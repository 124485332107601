import React from "react";
import { useError } from "./ErrorContext";
import "./ErrorPopup.css";

const ErrorPopup = () => {
  const { error, clearError } = useError();

  if (!error) return null;

  return (
    <div className="error-overlay" onClick={clearError}>
      <div className="error-popup" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={clearError}>
          &times;
        </button>
        <h3>{error}</h3>
      </div>
    </div>
  );
};

export default ErrorPopup;
