import React from "react";
import { Navigate } from "react-router-dom";
import { useData } from "./DataContext";
import { auth } from "../firebase";
import { getAuth } from "firebase/auth";

const ProtectedRoute = ({ children, isAdmin = false }) => {
  const { userData } = useData();
  const auth = getAuth();

  const user = auth.currentUser;

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (isAdmin && !(userData?.adminIds?.length > 0)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
