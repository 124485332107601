import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useLoading } from "./context/LoadingContext";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import LoadingPopup from "./context/LoadingPopup";
import ErrorPopup from "./context/ErrorPopup";

import Landing from "./pages/Landing-Home";
import About from "./pages/About";
import Interns from "./pages/Interns";
import Features from "./pages/Features";
import Clients from "./pages/Clients";

import Login from "./auth/Login";
import Register from "./auth/Register";

import Home from "./appviews/Home";
import Reserve from "./appviews/Reserve";
import Calendar from "./appviews/Calendar";

import Requests from "./adminviews/Requests";
import Display from "./adminviews/Display";

import ProtectedRoute from "./context/ProtectedRoute";
import NotFound from "./pages/NotFound";
import "./App.css";

const App = () => {
  const location = useLocation();
  const { isLoading, message } = useLoading();

  return (
    <>
      {location.pathname === "/" && <Header />}{" "}
      {location.pathname === "/about" && <Header />}{" "}
      {location.pathname === "/features" && <Header />}{" "}
      {location.pathname === "/clients" && <Header />}{" "}
      {location.pathname === "/interns" && <Header />}{" "}
      {location.pathname === "/register" && <Header />}{" "}
      {location.pathname === "/login" && <Header />}{" "}
      {location.pathname === "/home" && <Sidebar />}{" "}
      {location.pathname === "/reserve" && <Sidebar />}{" "}
      {location.pathname === "/calendar" && <Sidebar />}{" "}
      {location.pathname === "/payment" && <Sidebar />}{" "}
      {location.pathname === "/requests" && <Sidebar />}{" "}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/interns" element={<Interns />} />

        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reserve"
          element={
            <ProtectedRoute>
              <Reserve />
            </ProtectedRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />

        <Route
          path="/requests"
          element={
            <ProtectedRoute isAdmin={true}>
              <Requests />
            </ProtectedRoute>
          }
        />

        <Route
          path="/display"
          element={
            <ProtectedRoute isAdmin={true}>
              <Display />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <LoadingPopup isLoading={isLoading} message={message} />
      <ErrorPopup />
    </>
  );
};

export default App;
