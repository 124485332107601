import React from "react";
import "./ConfirmationPopupLite.css";

const ConfirmationPopupLite = ({ content, onClose }) => {
  return (
    <div className="confirmation-popup-lite-overlay" onClick={onClose}>
      <div
        className="confirmation-popup-lite"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {content.mainMessage ? <h3>{content.mainMessage}</h3> : null}
        {content.subMessage ? <h4>{content.subMessage}</h4> : null}

        {content.childComponent && (
          <div className="child">{content.childComponent}</div>
        )}

        <div className="confirmation-popup-lite-button-container">
          <button className="confirmation-popup-lite-confirm" onClick={onClose}>
            <h3>OK</h3>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopupLite;
