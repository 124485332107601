import React, { useState } from "react";
import "./OrganizationSelect.css";

const OrganizationSelect = ({
  onClose,
  organizationsList,
  currentSelectedOrganization,
  onSelectOrganization,
  multiple,
}) => {
  // Detects if organizationId is already selected
  const isSelected = (organization) => {
    if (multiple) {
      return currentSelectedOrganization.some(
        (selected) => selected === organization.organizationId
      );
    } else {
      return (
        currentSelectedOrganization &&
        currentSelectedOrganization.organizationId ===
          organization.organizationId
      );
    }
  };

  // State for filter input
  const [filter, setFilter] = useState("");

  // Handler to update the filter
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredOrganizations = organizationsList.filter((organization) =>
    organization.name.toLowerCase().includes(filter.toLowerCase())
  );

  const OrganizationCard = ({ organization }) => {
    return (
      <button
        className={`organization-card ${
          isSelected(organization) ? "selected" : ""
        }`}
        onClick={() => onSelectOrganization(organization)}
      >
        <h3>{organization.name}</h3>
        {multiple ? <i className="fas fa-plus"></i> : null}
      </button>
    );
  };

  return (
    <div className="organization-select-overlay" onClick={onClose}>
      <div className="organization-select" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h3>Select an Organization</h3>
        <div className="organization-search">
          <input
            type="text"
            name="eventName"
            id="eventName"
            value={filter}
            placeholder="Search for a location"
            onChange={handleFilterChange}
          />
          <i className="fas fa-search"></i>
        </div>

        <div className="organization-list">
          {filteredOrganizations.length > 0 ? (
            filteredOrganizations.map((organization) => (
              <OrganizationCard
                key={organization.organizationId}
                organization={organization}
              />
            ))
          ) : (
            <div className="organization-empty">No organizations found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationSelect;
