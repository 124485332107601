import React from "react";
import "./ReserveBlockDetail.css";

const ReserveBlockDetail = ({ reservation, onClose }) => {
  // Convert Cost Int to String
  function formatCost(cost) {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  }

  // Convert Time to String
  function formatTimeTo12Hour(time24) {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 === 0 ? 12 : hours % 12;
    return `${hours12}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
  }

  // Convert Materials Array to String
  const formatMaterials = (materials) => {
    if (!materials || materials.length === 0) return "None";
    return materials
      .map((material) => `${material.count} ${material.name}`)
      .join(", ");
  };

  return (
    <div className="reserve-block-detail-overlay" onClick={onClose}>
      <div
        className="reserve-block-detail"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{reservation.eventName}</h2>
        <h3>
          Location:{" "}
          <span className="unbold">{reservation.selectedLocation}</span>
        </h3>
        <h3>
          Activity Type: <span className="unbold">{reservation.activity}</span>
        </h3>
        <h3>
          Party Size: <span className="unbold">{reservation.partySize}</span>
        </h3>
        <h3>
          Time:{" "}
          <span className="unbold">
            {formatTimeTo12Hour(reservation.startTime)}-
            {formatTimeTo12Hour(reservation.endTime)}
          </span>
        </h3>
        <h3>
          Cost: <span className="unbold">{formatCost(reservation.cost)}</span>
        </h3>
        <h3>
          Materials:{" "}
          <span className="unbold">
            {formatMaterials(reservation.materialsNeeded)}
          </span>
        </h3>
        <h3>
          Comments:{" "}
          <span className="unbold">
            {reservation.comments === "" ? " None" : null}
          </span>
        </h3>
        {reservation.comments !== "" ? (
          <div className="reserve-block-detail-comments">
            <p>{reservation.comments}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReserveBlockDetail;
