import { React } from "react";
import "./MaterialsChip.css";

const MaterialsChip = ({
  material,
  onPlusMaterial,
  onMinusMaterial,
  onRemoveMaterial,
}) => {
  return (
    <div className="materials-chip">
      <button type="button" onClick={() => onRemoveMaterial(material)}>
        <i className="fa-solid fa-xmark"></i>
      </button>
      {material.count} - {material.name}
      <div className="materials-chip-increment">
        <button type="button" onClick={() => onMinusMaterial(material)}>
          <i className="fa-solid fa-chevron-down"></i>
        </button>
        <button type="button" onClick={() => onPlusMaterial(material)}>
          <i className="fa-solid fa-chevron-up"></i>
        </button>
      </div>
    </div>
  );
};

export default MaterialsChip;
