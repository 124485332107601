import React, { useState } from "react";
import "./Register.css";
import logo from "../images/logo-square.png";
import OrganizationSelect from "../components/Popups/OrganizationSelect";

import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

import { auth } from "../firebase";
import { useData } from "../context/DataContext";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();
  const { logInUser, searchOrganizations } = useData();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedOrganizationsIds, setSelectedOrganizationsIds] = useState([]);
  const [groupClass, setGroupClass] = useState("");

  const [error, setError] = useState("");

  // Register the user
  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (selectedOrganizationsIds.length === 0) {
      setError("Please choose at least 1 organization");
      return;
    }

    if (!groupClass) {
      setError("Please specify your use for Bookin");
      return;
    }

    setError("");

    setMessage("Creating account...");
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userData = {
        firstName: firstName,
        lastName: lastName,
        groupClass: groupClass,
        balance: 0,
        groupClass: groupClass,
        organizationIds: selectedOrganizationsIds,
        reservationIds: [],
      };

      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        userId: user.uid,
        ...userData,
        createdAt: new Date(),
      });
      await logInUser(user.uid);

      navigate("/home");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setMessage("");
    }
  };

  const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);
  const [listedOrganizations, setListedOrganizations] = useState([]);

  // Organization click handler
  const handleOrganizationSelect = async () => {
    if (listedOrganizations.length === 0) {
      setMessage("Loading organizations...");
      setLoading(true);
      try {
        const listedOrganizations = await searchOrganizations({ all: true });
        setListedOrganizations(listedOrganizations);
        setShowOrganizationSelect(true);
        document.body.classList.add("no-scroll");
      } catch (error) {
        throwError("Error fetching organizations:", error);
      } finally {
        setLoading(false);
        setMessage("");
      }
    } else {
      setShowOrganizationSelect(true);
      document.body.classList.add("no-scroll");
    }
  };

  // Set selectedOrganization
  const handleSelectOrganization = async (organization) => {
    setSelectedOrganizationsIds((prevData) => {
      if (prevData.includes(organization.organizationId)) {
        return prevData.filter((id) => id !== organization.organizationId);
      } else {
        return [...prevData, organization.organizationId];
      }
    });
  };

  // Close Organization Details
  const closeOrganizationSelect = () => {
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div className="register-page">
      <div className="register-content">
        <div className="register-header">
          <img src={logo} alt="logo" />
          <h2>Sign Up</h2>
        </div>

        <form onSubmit={handleRegister}>
          <div className="auth-info">
            <div className="firstName">
              <input
                type="firstName"
                name="firstName"
                id="firstName"
                value={firstName}
                placeholder="First Name"
                onChange={(t) => setFirstName(t.target.value)}
                autoComplete="off"
                required
              />
            </div>

            <div className="lastName">
              <input
                type="lastName"
                name="lastName"
                id="lastName"
                value={lastName}
                placeholder="Last Name"
                onChange={(t) => setLastName(t.target.value)}
                autoComplete="off"
                required
              />
            </div>

            <div className="email">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(t) => setEmail(t.target.value)}
                autoComplete="off"
                required
              />
            </div>

            <div className="password">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(t) => setPassword(t.target.value)}
                autoComplete="off"
                required
              />

              <button
                type="button"
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                {showPassword ? (
                  <i className="fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="fa-solid fa-eye"></i>
                )}
              </button>
            </div>

            <div className="confirm-password">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(t) => setConfirmPassword(t.target.value)}
                autoComplete="off"
                required
              />

              <button
                type="button"
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
              >
                {showConfirmPassword ? (
                  <i className="fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="fa-solid fa-eye"></i>
                )}
              </button>
            </div>

            <hr></hr>

            <button
              type="button"
              className="select-organizaton"
              onClick={() => handleOrganizationSelect()}
            >
              {selectedOrganizationsIds.length > 0
                ? `${selectedOrganizationsIds.length} organization(s) selected`
                : "Select Organization(s)"}
            </button>

            <select
              className="select-group"
              value={groupClass}
              onChange={(e) => setGroupClass(e.target.value)}
            >
              <option value="">What will you use Bookin for?</option>
              <option value="I">
                City/school sponsored activities or programs
              </option>
              <option value="II">
                Non-profit events hosted by local youth or community
                organizations
              </option>
              <option value="III">
                Non-profit or community events hosted by residents for
                recreational use
              </option>
              <option value="IV">
                Commerical purposes for individuals, private agencies, or
                vendors for profit
              </option>
            </select>
          </div>

          {error && <p>{error}</p>}

          <button className="register-button" type="submit">
            <h3>Get Started</h3>
          </button>
        </form>
      </div>

      {showOrganizationSelect ? (
        <OrganizationSelect
          onClose={closeOrganizationSelect}
          organizationsList={listedOrganizations}
          currentSelectedOrganization={selectedOrganizationsIds}
          onSelectOrganization={handleSelectOrganization}
          multiple={true}
        />
      ) : null}
    </div>
  );
};

export default Register;
