import React from "react";
import "./ConfirmationPopup.css";

const ConfirmationPopup = ({ content, onClose, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-popup-overlay" onClick={onClose}>
      <div className="confirmation-popup" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h3>{content.mainMessage}</h3>
        {content.subMessage ? <h4>{content.subMessage}</h4> : null}

        {content.childComponent && (
          <div className="child">{content.childComponent}</div>
        )}

        <div className="confirmation-popup-button-container">
          <button className="confirmation-popup-cancel" onClick={onCancel}>
            <h3>Cancel</h3>
          </button>
          <button className="confirmation-popup-confirm" onClick={onConfirm}>
            <h3>Confirm</h3>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
