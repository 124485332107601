import React, { useState, useEffect, useRef } from "react";
import "./Requests.css";
import ReservationDetail from "../components/Popups/ReservationDetail";
import OrganizationSelect from "../components/Popups/OrganizationSelect";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";

import { Timestamp } from "firebase/firestore";
import { useData } from "../context/DataContext";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";

const Requests = () => {
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();
  const {
    userData,
    fetchOrganizationReservations,
    updateReservation,
    organizationListen,
    createNotification,
  } = useData();

  const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // Organization click handler
  const handleOrganizationSelect = () => {
    setShowOrganizationSelect(true);
    document.body.classList.add("no-scroll");
  };

  // Set selectedOrganization
  const handleSelectOrganization = async (organization) => {
    setSelectedOrganization(organization);
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
    await loadOrganizationData(organization.organizationId);
  };

  // Close Organization Details
  const closeOrganizationSelect = () => {
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
  };

  // Populate organization data
  const loadOrganizationData = async (organizationId) => {
    setLoading(true);
    setMessage("Loading reservations...");
    try {
      const organizationReservations = await fetchOrganizationReservations(
        { status: ["Approved", "Pending"] },
        organizationId
      );
      if (organizationReservations) {
        setExistingReservations(
          organizationReservations
            .filter((r) => r.status === "Approved")
            .map((event) => mapReservation(event, "Others"))
        );
        setRequests(
          organizationReservations.filter((r) => r.status === "Pending")
        );
      }
      setLoading(false);
      setMessage("");
    } catch (error) {
      throwError(`Error loading reservations ${error}`);
      setLoading(false);
      setMessage("");
    }
  };

  // Detects changes to reservations and updates the data
  useEffect(() => {
    if (!selectedOrganization) return;

    const unsubscribe = organizationListen(
      selectedOrganization.organizationId,
      (newData) => {
        const approvedReservations = newData.filter(
          (r) => r.status === "Approved"
        );
        const pendingRequests = newData.filter((r) => r.status === "Pending");

        setExistingReservations(
          approvedReservations.map((event) => mapReservation(event, "Others"))
        );
        setRequests(pendingRequests);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [selectedOrganization]);

  const [existingReservations, setExistingReservations] = useState([]);
  const [requests, setRequests] = useState([]);

  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [selectedReservationDetail, setSelectedReservationDetail] =
    useState(null);

  // Detail click handler
  const handleDetailClick = (request) => {
    setSelectedReservationDetail(request);
    setShowReservationDetail(true);
    document.body.classList.add("no-scroll");
  };

  // Close Reservation Details
  const closeReservationDetails = () => {
    setSelectedReservationDetail(null);
    setShowReservationDetail(false);
    document.body.classList.remove("no-scroll");
  };

  // Convert Date Dictionary to String
  const formatDate = (datePicked) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[datePicked.month - 1]} ${datePicked.day}, ${
      datePicked.year
    }`;
  };

  // Convert Time to String
  const formatTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amPm}`;
  };

  const [selectedRequest, setSelectedRequest] = useState(null);

  // Handle Selecting a Request
  const handleSelectRequest = async (request) => {
    const selectingRequest = async () => {
      if (
        selectedRequest &&
        selectedRequest.reservationId === request.reservationId
      ) {
        setSelectedRequest(null);
        setSelectedRequestConflicts([]);
        setCalendarDisplayedReservations([]);
      } else {
        setSelectedRequest(request);
        handleSelectedRequestConflicts(request);
        setCalendarDisplayedReservations(
          existingReservations
            .concat(mapReservation(request, request.activity))
            .filter(
              (r) =>
                r.extendedProps.selectedLocation === request.selectedLocation
            )
        );
      }
    };

    setMessage("Selecting request...");
    setLoading(true);
    await selectingRequest();
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(request.startTime.toDate());
    }
    setLoading(false);
    setMessage("");
  };

  const [selectedRequestConflicts, setSelectedRequestConflicts] = useState([]);

  // Handle Finding Conflicts with the Current Request
  const handleSelectedRequestConflicts = (request) => {
    const conflicts = [];
    for (const otherRequest of requests) {
      if (
        otherRequest.reservationId !== request.reservationId &&
        checkConflict(request, otherRequest)
      ) {
        conflicts.push(otherRequest);
      }
    }
    setSelectedRequestConflicts(conflicts);
  };

  // Check if there is a conflict
  function checkConflict(thisRequest, otherRequest) {
    const startTime1 = new Date(thisRequest.startTime).getTime();
    const endTime1 = new Date(thisRequest.endTime).getTime();
    const startTime2 = new Date(otherRequest.startTime).getTime();
    const endTime2 = new Date(otherRequest.endTime).getTime();

    const timeOverlap = startTime1 < endTime2 && endTime1 > startTime2;
    const sameLocation =
      thisRequest.selectedLocation === otherRequest.selectedLocation;
    const sameDay =
      thisRequest.datePicked.day === otherRequest.datePicked.day &&
      thisRequest.datePicked.month === otherRequest.datePicked.month &&
      thisRequest.datePicked.year === otherRequest.datePicked.year;

    return sameLocation && timeOverlap && sameDay;
  }

  const [calendarDisplayedReservations, setCalendarDisplayedReservations] =
    useState([]);
  const calendarRef = useRef(null);

  // Force full calendar to render fully
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.updateSize();
    }
  }, []);

  // Map a reservation object to a Full Calendar friendly format
  function mapReservation(event, color) {
    return {
      classNames: color,
      id: event.reservationId,
      title: event.eventName,
      start: event.startTime.toDate(),
      end: event.endTime.toDate(),
      extendedProps: {
        activity: event.activity,
        comments: event.comments,
        cost: event.cost,
        datePicked: event.datePicked,
        endTime: event.endTime,
        eventName: event.eventName,
        materialsNeeded: event.materialsNeeded,
        organizationId: event.organizationId,
        paid: event.paid,
        partySize: event.partySize,
        reservationId: event.reservationId,
        selectedLocation: event.selectedLocation,
        startTime: event.startTime,
        status: event.status,
        userId: event.userId,
      },
    };
  }

  // Handle when a calendar event is clicked
  const handleCalendarEventClick = (info) => {
    setSelectedReservationDetail(info.event.extendedProps);
    setShowReservationDetail(true);
    document.body.classList.add("no-scroll");
  };

  const [comments, setComments] = useState("");

  // Handle updating the comments
  const handleCommentsChange = (comment) => {
    setComments(comment.target.value);
  };

  const [showComments, setShowComments] = useState(false);

  // Handle showing the comment box
  const handleCommentsButton = () => {
    if (showComments) {
      setShowComments(false);
      setComments("");
    } else {
      setShowComments(true);
    }
  };

  const [filter, setFilter] = useState(null);

  // Search for an event based on its name
  const filteredRequests = filter
    ? requests.filter((request) => request.activity === filter)
    : requests;

  //Handle setting the requests filter
  const handleFilter = (newFilter) => {
    if (newFilter === filter) {
      setFilter(null);
    } else {
      setFilter(newFilter);
    }
  };

  // Handle Rejecting a request
  const handleReject = async () => {
    setLoading(true);
    setMessage("Rejecting reservation...");
    try {
      await updateReservation(
        { status: "Rejected" },
        selectedRequest.reservationId,
        selectedRequest.organizationId
      );
      await createNotification(
        {
          isRead: false,
          timestamp: Timestamp.now(),
          subject: "Rejected",
          text: `An Admin has rejected your reservation for ${
            selectedRequest.eventName
          } on ${formatDate(selectedRequest.datePicked)} from ${formatTime(
            selectedRequest.startTime
          )} to ${formatTime(selectedRequest.endTime)}.`,
        },
        selectedRequest.userId
      );
      setLoading(false);
      setMessage("");
      cleanupVariables();
    } catch (error) {
      throwError(`Error rejecting reservation ${error}`);
      setLoading(false);
      setMessage("");
    }
  };

  // Handle Accepting a request
  const handleAccept = async () => {
    setLoading(true);
    setMessage("Approving reservation...");
    try {
      await updateReservation(
        { status: "Approved" },
        selectedRequest.reservationId,
        selectedRequest.organizationId
      );
      await createNotification(
        {
          isRead: false,
          timestamp: Timestamp.now(),
          subject: "Approved",
          text: `An admin has approved your reservation for ${
            selectedRequest.eventName
          } on ${formatDate(selectedRequest.datePicked)} from ${formatTime(
            selectedRequest.startTime
          )} to ${formatTime(selectedRequest.endTime)}.`,
        },
        selectedRequest.userId
      );
      setLoading(false);
      setMessage("");
      cleanupVariables();
    } catch (error) {
      throwError(`Error approving reservation ${error}`);
      setLoading(false);
      setMessage("");
    }
  };

  // Clear variables
  const cleanupVariables = () => {
    setSelectedRequest(null);
    setSelectedRequestConflicts([]);
    setCalendarDisplayedReservations([]);
    setComments("");
    setShowComments(false);
  };

  return (
    <div className="safe-area">
      <div className="requests-page">
        <div className="requests-header">
          <button onClick={handleOrganizationSelect}>
            <h3>
              {selectedOrganization
                ? selectedOrganization.name
                : "No Selected Organization"}{" "}
              <i className="fas fa-chevron-right"></i>
            </h3>
          </button>

          <div className="filters-container">
            <button
              className={filter === "Athletics" ? "active" : ""}
              onClick={() => handleFilter("Athletics")}
            >
              Athletics
            </button>
            <button
              className={filter === "Clubs" ? "active" : ""}
              onClick={() => handleFilter("Clubs")}
            >
              Clubs
            </button>
            <button
              className={filter === "Renters" ? "active" : ""}
              onClick={() => handleFilter("Renters")}
            >
              Renters
            </button>
            <button
              className={filter === "Other" ? "active" : ""}
              onClick={() => handleFilter("Other")}
            >
              Others
            </button>
          </div>
        </div>

        <div className="requests-page-columns">
          <div className="requests-container">
            {filteredRequests.length > 0 ? (
              filteredRequests.map((request) => (
                <div
                  className={`request-card ${request.activity} ${
                    selectedRequest &&
                    selectedRequest.reservationId === request.reservationId
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelectRequest(request)}
                  key={request.reservationId}
                >
                  <div className="request-info-container">
                    <div className="request-name">
                      <h4>{request.eventName}</h4>
                      <h4>
                        {request.firstName} {request.lastName}
                      </h4>
                    </div>
                    <div className="request-details">
                      <h4>{request.selectedLocation}</h4>
                      <h4>{formatDate(request.datePicked)}</h4>
                      <h4>
                        {formatTime(request.startTime)}-
                        {formatTime(request.endTime)}
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-selected-request">No pending requests</div>
            )}
          </div>

          <div className="middle-container">
            <div className="details-container">
              {selectedRequest ? (
                <div className="details-info">
                  <div className="details-header">
                    <button onClick={() => handleDetailClick(selectedRequest)}>
                      <h3>
                        <i className="fa-solid fa-circle-info"></i>
                      </h3>
                    </button>
                    <h3>{selectedRequest.eventName}</h3>
                  </div>

                  <div className="details-attributes">
                    <h4>{selectedRequest.selectedLocation}</h4>
                    <h4>{formatDate(selectedRequest.datePicked)}</h4>
                    <h4>
                      {formatTime(selectedRequest.startTime)}-
                      {formatTime(selectedRequest.endTime)}
                    </h4>
                  </div>

                  <div className="comment-box">
                    <button onClick={() => handleCommentsButton()}>
                      {showComments ? (
                        <>
                          <i className="fas fa-xmark"></i> Remove Comments
                        </>
                      ) : (
                        <>
                          <i className="fas fa-plus"></i> Add Comments
                        </>
                      )}
                    </button>
                    {showComments ? (
                      <textarea
                        value={comments}
                        onChange={handleCommentsChange}
                      ></textarea>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="no-selected-request">
                  <p>Selected request will appear here</p>
                </div>
              )}
            </div>

            <div className="conflicts-container">
              {selectedRequest ? (
                selectedRequestConflicts.length > 0 ? (
                  selectedRequestConflicts.map((request) => (
                    <div
                      className="request-card Conflict"
                      onClick={() => handleDetailClick(request)}
                      key={request.reservationId}
                    >
                      <div className="request-info-container">
                        <div className="request-name">
                          <h4>{request.eventName}</h4>
                          <h4>
                            {request.firstName} {request.lastName}
                          </h4>
                        </div>
                        <div className="request-details">
                          <h4>{request.selectedLocation}</h4>
                          <h4>{formatDate(request.datePicked)}</h4>
                          <h4>
                            {formatTime(request.startTime)}-
                            {formatTime(request.endTime)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-selected-request">
                    <p>No conflicts with other requests</p>
                  </div>
                )
              ) : (
                <div className="no-selected-request">
                  <p>Conflicts with other requests will appear here</p>
                </div>
              )}
            </div>
          </div>

          <div className="calendar-container">
            {selectedRequest ? (
              <div className="calendar-container-content">
                <div className="requests-calendar">
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[timeGridPlugin]}
                    headerToolbar={{
                      left: "title",
                      center: "",
                      right: "prev,next",
                    }}
                    footerToolbar={{
                      left: "timeGridWeek,timeGridDay",
                      center: "",
                      right: "today",
                    }}
                    events={calendarDisplayedReservations}
                    eventClick={handleCalendarEventClick}
                    slotEventOverlap={false}
                    slotMinTime="05:00:00"
                    slotMaxTime="23:00:00"
                    expandRows={true}
                    eventTimeFormat={{
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    }}
                    aspectRatio={null}
                  />
                </div>

                <div className="review-buttons">
                  <button className="reject" onClick={() => handleReject()}>
                    <i className="fa-solid fa-xmark"></i> Reject
                  </button>
                  <button className="accept" onClick={() => handleAccept()}>
                    <i className="fa-solid fa-check"></i> Accept
                  </button>
                </div>
              </div>
            ) : (
              <div className="no-selected-request">
                <p>Event schedule will appear here</p>
              </div>
            )}
          </div>
        </div>

        {showReservationDetail ? (
          <ReservationDetail
            reservation={selectedReservationDetail}
            onClose={closeReservationDetails}
            editable={false}
          />
        ) : null}

        {showOrganizationSelect ? (
          <OrganizationSelect
            onClose={closeOrganizationSelect}
            organizationsList={userData.adminOrganizations}
            currentSelectedOrganization={selectedOrganization}
            onSelectOrganization={handleSelectOrganization}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Requests;
