import React, { createContext, useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import { db, auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  arrayUnion,
  orderBy,
  query,
  where,
  doc,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";
import { useNavigate } from "react-router-dom";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const navigate = useNavigate();
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();
  const [userData, setUserData] = useState(null);

  // Log in and load user data
  const logInUser = async (userId) => {
    setLoading(true);
    setMessage("Logging in...");
    try {
      const userData = await fetchUserData(userId);
      if (userData) {
        setUserData(userData);
        navigate("/home");
      }
    } catch (error) {
      throwError(`Error loading user data ${error}`);
    } finally {
      setLoading(false);
      setMessage("");
    }
  };

  // Log out user
  const logOutUser = async () => {
    setLoading(true);
    setMessage("Logging out...");
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/login");
    } catch (error) {
      throwError("Error logging out:", error);
    } finally {
      setLoading(false);
      setMessage("");
    }
  };

  // Automatically fetch user data if a user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        logInUser(user.uid);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Fetch Location Data from CSV of database
  const parseLocationData = async (csvURL, groupClass) => {
    try {
      const response = await fetch(csvURL);
      const csvData = await response.text();

      return new Promise((resolve) => {
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const rows = results.data;
            const nestedDict = {};

            rows.forEach((row) => {
              const type = row["Type"];
              const subCategory = row["Sub-category"];
              const roomName = row["Room Name"];
              const pricing = row[`Group ${groupClass} Pricing`];

              if (!nestedDict[type]) {
                nestedDict[type] = {};
              }

              if (subCategory) {
                if (!nestedDict[type][subCategory]) {
                  nestedDict[type][subCategory] = {};
                }
                nestedDict[type][subCategory][roomName] =
                  parseFloat(pricing) || 0;
              } else {
                nestedDict[type][roomName] = parseFloat(pricing) || 0;
              }
            });
            resolve(nestedDict);
          },
          error: (error) => {
            console.error(`Error: ${error.message}`);
          },
        });
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  // Fetch Material Data from CSV of database
  const parseMaterialData = async (csvURL, groupClass) => {
    try {
      const response = await fetch(csvURL);
      const csvData = await response.text();

      return new Promise((resolve) => {
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const rows = results.data;
            const nestedDict = {};

            rows.forEach((row) => {
              const type = row["Pricing Type"];
              const materialName = row["Rental Equipment"];
              const pricing = row[`Group ${groupClass} Pricing`];

              if (!nestedDict[type]) {
                nestedDict[type] = {};
              }
              nestedDict[type][materialName] = parseFloat(pricing) || 0;
            });
            resolve(nestedDict);
          },
          error: (error) => {
            console.error(`Error: ${error.message}`);
          },
        });
      });
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  // Fetch organization data given their ID (excluding reservations)
  const fetchOrganizationData = async (
    { locations, materials, groupClass },
    organizationId
  ) => {
    try {
      const organizationRef = doc(db, "Organizations", organizationId);
      const organizationSnapshot = await getDoc(organizationRef);

      if (organizationSnapshot.exists()) {
        let organizationData = organizationSnapshot.data();

        // Adds location data
        if (locations) {
          const locationDataCSV = organizationData["roomList"];
          const locationData = await parseLocationData(
            locationDataCSV,
            groupClass
          );
          organizationData = {
            ...organizationData,
            locationData: locationData,
          };
        }

        // Adds materials data
        if (materials) {
          const materialsDataCSV = organizationData["rentalEquipment"];
          const materialsData = await parseMaterialData(
            materialsDataCSV,
            groupClass
          );
          organizationData = {
            ...organizationData,
            materialsData: materialsData,
          };
        }

        return organizationData;
      } else {
        console.error(`No organization found with ID: ${organizationId}`);
        return null;
      }
    } catch (error) {
      console.error(
        `Error fetching the organization with ID: ${organizationId}`,
        error
      );
      throw error;
    }
  };

  // Fetches organization reservations (able to query by status, list of locations, or fetch all)
  const fetchOrganizationReservations = async (
    { status, queryLocations },
    organizationId
  ) => {
    try {
      const reservationsRef = collection(
        db,
        "Organizations",
        organizationId,
        "reservations"
      );
      let reservationQueries = [];

      if (!status && (!queryLocations || queryLocations.length === 0)) {
        const q = query(reservationsRef, orderBy("startTime", "asc"));
        reservationQueries.push(getDocs(q));
      } else {
        if (queryLocations && queryLocations.length > 0) {
          const locationChunks = [];
          for (let i = 0; i < queryLocations.length; i += 10) {
            locationChunks.push(queryLocations.slice(i, i + 10));
          }

          locationChunks.forEach((chunk) => {
            let q = query(
              reservationsRef,
              where("selectedLocation", "in", chunk)
            );
            if (status) {
              q = query(
                q,
                where("status", "in", status),
                orderBy("startTime", "asc")
              );
            } else {
              q = query(q, orderBy("startTime", "asc"));
            }
            reservationQueries.push(getDocs(q));
          });
        } else if (status) {
          const q = query(
            reservationsRef,
            where("status", "in", status),
            orderBy("startTime", "asc")
          );
          reservationQueries.push(getDocs(q));
        }
      }
      const reservationsSnapshots = await Promise.all(reservationQueries);
      const reservations = reservationsSnapshots.flatMap((snapshot) =>
        snapshot.docs.map((doc) => doc.data())
      );

      return reservations;
    } catch (error) {
      console.error(
        `Error fetching reservations for organization with ID: ${organizationId}`,
        error
      );
      throw error;
    }
  };

  // Search for Organization names with a list of Ids (excluding reservation data)
  const searchOrganizations = async ({ all, organizationIds }) => {
    try {
      const organizationsListRef = doc(
        db,
        "Organizations",
        "OrganizationsList"
      );
      const organizationsListSnapshot = await getDoc(organizationsListRef);
      if (organizationsListSnapshot.exists()) {
        const organizationsList =
          organizationsListSnapshot.data()["organizations"];

        if (all) {
          return organizationsList;
        } else if (organizationIds) {
          const filteredOrganizations = organizationsList.filter((org) =>
            organizationIds.includes(org.organizationId)
          );
          return filteredOrganizations;
        }
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  };

  // Look up a reservation's ID in given an organization's ID and return the reservation data
  const lookupReservation = async (reservationId, organizationId) => {
    try {
      const reservationRef = doc(
        db,
        "Organizations",
        organizationId,
        "reservations",
        reservationId
      );
      const reservationSnapshot = await getDoc(reservationRef);
      if (reservationSnapshot.exists()) {
        const reservationData = reservationSnapshot.data();
        return reservationData;
      } else {
        console.error(`Reservation ${reservationId} does not exist`);
        return null;
      }
    } catch (error) {
      console.error(
        `Error finding reservation ${reservationId} for organization ${organizationId}:`,
        error
      );
      throw error;
    }
  };

  // Look up a list of reservation IDs given an organization ID(s) and return the reservation data
  const batchLookupReservations = async (reservationIds, organizationIds) => {
    try {
      const reservationRefs = organizationIds.flatMap((organizationId) =>
        reservationIds.map((reservationId) =>
          doc(
            db,
            "Organizations",
            organizationId,
            "reservations",
            reservationId
          )
        )
      );
      const snapshots = await Promise.all(reservationRefs.map(getDoc));
      const reservations = snapshots
        .filter((snapshot) => snapshot.exists())
        .map((snapshot) => snapshot.data());

      reservations.sort(
        (a, b) => a.startTime.toMillis() - b.startTime.toMillis()
      );

      return reservations;
    } catch (error) {
      console.error("Error in batch lookup:", error);
      throw error;
    }
  };

  // Provides real-time updates for reservationd ata given its organizationId
  const organizationListen = (organizationId, callback) => {
    const unsubscribe = onSnapshot(
      collection(db, "Organizations", organizationId, "reservations"),
      (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({ ...doc.data() }));
        callback(newData);
      }
    );

    return unsubscribe;
  };

  // Fetch user data given their ID
  const fetchUserData = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        let userData = userSnapshot.data();

        const reservationIds = userData["reservationIds"];
        const organizationIds = userData["organizationIds"];
        const adminIds = userData["adminIds"];

        if (organizationIds && organizationIds.length > 0) {
          const userOrganizations = await searchOrganizations({
            organizationIds,
          });
          userData = {
            ...userData,
            organizations: userOrganizations,
          };
          if (reservationIds) {
            const userReservations = await batchLookupReservations(
              reservationIds,
              organizationIds
            );
            userData = {
              ...userData,
              reservations: userReservations,
            };
          }
        }

        if (adminIds && adminIds.length > 0) {
          const adminOrganizations = await searchOrganizations({
            organizationIds: adminIds,
          });
          userData = {
            ...userData,
            adminOrganizations: adminOrganizations,
          };
        }

        return userData;
      } else {
        console.error(`No user found with ID: ${userId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching the user: with ID: ${userId}`, error);
      throw error;
    }
  };

  // Update user data given their ID
  const updateUserData = async (userId, updatedFields) => {
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, updatedFields);
    } catch (error) {
      console.error(`Error fetching the user: with ID: ${userId}`, error);
      throw error;
    }
  };

  // Add a reservation to an organization and user's reservation ID list
  const createReservation = async (reservationData) => {
    try {
      const reservationsRef = collection(
        db,
        "Organizations",
        reservationData.organizationId,
        "reservations"
      );
      const newReservationRef = await addDoc(reservationsRef, reservationData);
      await updateDoc(newReservationRef, {
        reservationId: newReservationRef.id,
      });

      const userRef = doc(db, "users", reservationData.userId);
      await updateDoc(userRef, {
        reservationIds: arrayUnion(newReservationRef.id),
      });
    } catch (error) {
      console.error("Error adding reservation:", error);
      throw error;
    }
  };

  // Update a reservation field
  const updateReservation = async (
    updatedFields,
    reservationId,
    organizationId
  ) => {
    try {
      const reservationRef = doc(
        db,
        "Organizations",
        organizationId,
        "reservations",
        reservationId
      );
      await updateDoc(reservationRef, updatedFields);
    } catch (error) {
      console.error(
        `Error updating reservation ${reservationId} for organization ${organizationId}:`,
        error
      );
      throw error;
    }
  };

  // Fetch notifications given a user ID
  const fetchUserNotifications = async (userId) => {
    try {
      const notificationsRef = collection(db, "users", userId, "notifications");
      const notificationsSnapshot = await getDocs(notificationsRef);
      if (notificationsSnapshot.size !== 0) {
        const notifications = notificationsSnapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        return notifications;
      } else {
        return [];
      }
    } catch (error) {
      console.error(
        `Error fetching notifications for user with ID: ${userId}`,
        error
      );
      throw error;
    }
  };

  // Add a notifcation to a user document
  const createNotification = async (notificationData, userId) => {
    try {
      const notificationsRef = collection(db, "users", userId, "notifications");
      const newNotificationRef = await addDoc(
        notificationsRef,
        notificationData
      );
      await updateDoc(newNotificationRef, {
        notificationId: newNotificationRef.id,
      });
    } catch (error) {
      console.error("Error adding notification:", error);
      throw error;
    }
  };

  // Mark a notification as read
  const markNotificationRead = async (notificationId, userId) => {
    try {
      const notificationRef = doc(
        db,
        "users",
        userId,
        "notifications",
        notificationId
      );
      await updateDoc(notificationRef, { isRead: true });
    } catch (error) {
      console.error(
        `Error updating notification ${notificationId} for user ${userId}:`,
        error
      );
      throw error;
    }
  };

  return (
    <DataContext.Provider
      value={{
        userData,
        setUserData,

        logInUser,
        logOutUser,

        fetchOrganizationData,
        fetchOrganizationReservations,
        searchOrganizations,
        lookupReservation,
        organizationListen,
        batchLookupReservations,

        fetchUserData,
        updateUserData,

        createReservation,
        updateReservation,

        fetchUserNotifications,
        createNotification,
        markNotificationRead,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
