import React from "react";
import "./NotificationsBar.css";
import Notification from "./Notification";

const NotificationsBar = ({ notifications, onClose }) => {
  return (
    <>
      <div className="notifications-bar-overlay" onClick={onClose}>
        <div className="notifications-bar" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>

          <div className="section-header">
            <h2>Notifications</h2>
          </div>
          <div className="notification-container">
            {notifications ? (
              notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                />
              ))
            ) : (
              <div className="empty-notifications">No notifications</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsBar;
