import React from "react";

import "./ReservationCard.css";
import "../../constants/colors.css";

const ReservationCard = ({ reservation, onClick }) => {
  // Convert Date Dictionary to String
  const formatDate = (datePicked) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[datePicked.month - 1]} ${datePicked.day}, ${
      datePicked.year
    }`;
  };

  // Convert Time to String
  const formatTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amPm}`;
  };

  return (
    <>
      <div
        className={`reservation-card ${reservation.status}`}
        onClick={onClick}
      >
        <button>
          <h2 className="truncated-text">{reservation.eventName}</h2>
          <h3 className="truncated-text">{reservation.partySize} People</h3>
          <h3 className="truncated-text">{reservation.selectedLocation}</h3>
          <h3 className="truncated-text">
            {formatDate(reservation.datePicked)}
          </h3>
          <h3 className="truncated-text">
            {formatTime(reservation.startTime)}-
            {formatTime(reservation.endTime)}
          </h3>
        </button>
      </div>
    </>
  );
};

export default ReservationCard;
