import React, { useState, useRef, useEffect } from "react";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";
import { useData } from "../context/DataContext";

import "./Reserve.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { Timestamp } from "firebase/firestore";

import ReserveCreate from "../components/ReserveComponents/ReserveCreate";
import ReserveBlock from "../components/ReserveComponents/ReserveBlock";
import ReserveBlockDetail from "../components/ReserveComponents/ReserveBlockDetail";
import MaterialsSelect from "../components/ReserveComponents/MaterialsSelect";
import LocationSelect from "../components/ReserveComponents/LocationSelect";
import OrganizationSelect from "../components/Popups/OrganizationSelect";
import ReservationDetail from "../components/Popups/ReservationDetail";
import ConfirmationPopup from "../components/Popups/ConfirmationPopup";
import ConfirmationPopupLite from "../components/Popups/ConfirmationPopupLite";
import ReservationsItemized from "../components/ReservationsItemized";

const Reserve = () => {
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();

  const {
    userData,
    fetchOrganizationData,
    fetchOrganizationReservations,
    createReservation,
  } = useData();

  // Load organization data upon selection
  const loadOrganizationData = async (organizationId) => {
    try {
      setLoading(true);
      setMessage("Loading organization data...");
      const organizationData = await fetchOrganizationData(
        {
          locations: true,
          materials: true,
          groupClass: userData.groupClass,
        },
        organizationId
      );
      if (organizationData) {
        setSelectedOrganization(organizationData);
      }
      setLoading(false);
      setMessage("");
    } catch (error) {
      throwError(`Error loading organization data ${error}`);
      setLoading(false);
      setMessage("");
    }
  };

  const [mappedExistingReservations, setMappedExistingReservations] = useState(
    {}
  );

  const [formData, setFormData] = useState({
    activity: "",
    selectedLocation: "",
    eventName: "",
    partySize: "",
    startTime: "",
    endTime: "",
    materialsNeeded: [],
    comments: "",
    cost: 0,
  });

  // Find the specific rate in a CSV for a specific name
  function findCSVRate(data, target) {
    for (const type in data) {
      const typeData = data[type];
      if (typeof typeData === "object") {
        for (const subCategory in typeData) {
          const subCategoryData = typeData[subCategory];
          if (subCategoryData[target] !== undefined) {
            return subCategoryData[target];
          }
        }
      }
      if (typeData[target] !== undefined) {
        return typeData[target];
      }
    }
    return null;
  }

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);

  // Organization click handler
  const handleOrganizationSelect = () => {
    setShowOrganizationSelect(true);
    document.body.classList.add("no-scroll");
  };

  // Set selectedOrganization
  const handleSelectOrganization = async (organization) => {
    if (
      selectedOrganization &&
      organization.organizationId === selectedOrganization.organizationId
    ) {
      return;
    }

    if (
      selectedOrganization &&
      organization.organizationId !== selectedOrganization.organizationId
    ) {
      const confirmSwitch = await handleConfirmationPopup({
        mainMessage: "Are you sure you want to switch organizations?",
        subMessage: "Unsaved changes may be lost.",
      });

      if (!confirmSwitch) {
        return;
      }

      cleanupVariables();
    }

    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
    await loadOrganizationData(organization.organizationId);
  };

  // Close Organization Details
  const closeOrganizationSelect = () => {
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
  };

  const [showLocationSelect, setLocationSelect] = useState(false);
  const [currentLocationRate, setCurrentLocationRate] = useState(0);

  // Show Location Select
  const handleLocationSelect = () => {
    setLocationSelect(true);
    document.body.classList.add("no-scroll");
  };

  // Select a Location
  const handleSelectLocation = (location) => {
    setCurrentLocationRate(location.cost);
    setFormData({
      ...formData,
      selectedLocation: location.name,
    });
  };

  // Close Location Select
  const closeLocationSelect = () => {
    setLocationSelect(false);
    document.body.classList.remove("no-scroll");
  };

  const [showMaterialsSelect, setShowMaterialsSelect] = useState(false);

  // Show Materials Select
  const handleMaterialsSelect = () => {
    setShowMaterialsSelect(true);
    document.body.classList.add("no-scroll");
  };

  // Add Materials
  const handleAddMaterial = (material) => {
    const materialIndex = formData.materialsNeeded.findIndex(
      (m) => m.name === material.name
    );
    if (materialIndex !== -1) {
      const updatedMaterialsNeeded = [...formData.materialsNeeded];
      updatedMaterialsNeeded[materialIndex].count += 1;

      setFormData({
        ...formData,
        materialsNeeded: updatedMaterialsNeeded,
      });
    } else {
      const newMaterial = { ...material, count: 1 };
      setFormData({
        ...formData,
        materialsNeeded: [...formData.materialsNeeded, newMaterial],
      });
    }
  };

  // Subtract Materials
  const handleSubtractMaterial = (material) => {
    const materialIndex = formData.materialsNeeded.findIndex(
      (m) => m.name === material.name
    );

    if (
      materialIndex !== -1 &&
      formData.materialsNeeded[materialIndex].count > 1
    ) {
      const updatedMaterialsNeeded = [...formData.materialsNeeded];
      updatedMaterialsNeeded[materialIndex].count -= 1;
      setFormData({
        ...formData,
        materialsNeeded: updatedMaterialsNeeded,
      });
    } else if (
      materialIndex !== -1 &&
      formData.materialsNeeded[materialIndex].count === 1
    ) {
      const updatedMaterialsNeeded = formData.materialsNeeded.filter(
        (m) => m.name !== material.name
      );
      setFormData({
        ...formData,
        materialsNeeded: updatedMaterialsNeeded,
      });
    }
  };

  // Remove Materials
  const handleRemoveMaterial = (material) => {
    const updatedMaterialsNeeded = formData.materialsNeeded.filter(
      (m) => m.name !== material.name
    );
    setFormData({
      ...formData,
      materialsNeeded: updatedMaterialsNeeded,
    });
  };

  // Close Materials Select
  const closeMaterialsSelect = () => {
    setShowMaterialsSelect(false);
    document.body.classList.remove("no-scroll");
  };

  // Calculate Costs (for a single reservtion block)
  function calculateSingleCost(data) {
    let locationCost = 0;
    let materialCost = 0;

    const start = new Date(`1970-01-01T${data.startTime}:00`);
    const end = new Date(`1970-01-01T${data.endTime}:00`);

    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    // Room Rates
    if (
      data.selectedLocation &&
      data.startTime &&
      data.endTime &&
      start < end
    ) {
      const hours = Math.ceil(
        (timeToMinutes(data.endTime) - timeToMinutes(data.startTime)) / 60
      );
      locationCost = hours * currentLocationRate;
    }

    // Material Rates
    if (data.materialsNeeded && data.materialsNeeded.length > 0) {
      materialCost += data.materialsNeeded.reduce((sum, material) => {
        return sum + material.cost * material.count;
      }, 0);
    }

    return locationCost + materialCost;
  }

  // Recalculate cost whenever formData or currentLocationRate changes
  useEffect(() => {
    if (formData.selectedLocation && formData.startTime && formData.endTime) {
      setFormData((prevData) => ({
        ...prevData,
        cost: calculateSingleCost(prevData),
      }));
    }
  }, [
    formData.selectedLocation,
    formData.startTime,
    formData.endTime,
    currentLocationRate,
    formData.materialsNeeded,
  ]);

  const [reserveBlocks, setReserveBlocks] = useState({});
  const blockID = useRef(0);

  // Create Block
  const handleReservationSubmit = (data) => {
    const materialsCopy = JSON.parse(JSON.stringify(data.materialsNeeded));
    const newBlock = {
      ...data,
      organizationId: selectedOrganization.organizationId,
      materialsNeeded: materialsCopy,
      blockID: blockID.current,
    };
    const locationKey = newBlock.selectedLocation;

    setReserveBlocks((prevBlocks) => {
      const updatedBlocks = { ...prevBlocks };
      if (!updatedBlocks[locationKey]) {
        updatedBlocks[locationKey] = [];
        setAddedLocationsList((prevList) => {
          if (!prevList.includes(locationKey)) {
            return [...prevList, locationKey];
          }
          return prevList;
        });
        setCurrentLocation(locationKey);
        setRequestedCalendarEvents((prevList) => {
          return {
            ...prevList,
            [locationKey]: [],
          };
        });
      }
      updatedBlocks[locationKey] = [...updatedBlocks[locationKey], newBlock];
      return updatedBlocks;
    });
    blockID.current++;
  };

  const reserveBlockContainerRef = useRef(null);

  // Initialize Draggable for Reserve Block
  useEffect(() => {
    if (!reserveBlockContainerRef.current) {
      console.error("Reserve block container ref is null.");
      return;
    }

    new Draggable(reserveBlockContainerRef.current, {
      itemSelector: ".reserve-block",
      eventData: (eventEl) => {
        const title = eventEl.getAttribute("data-title");
        const extendedProps = JSON.parse(
          eventEl.getAttribute("data-extended-props")
        );
        return {
          title,
          extendedProps,
          create: false,
        };
      },
    });
  }, []);

  // Copy Block Data into Form
  const handleCopyBlock = (data) => {
    const materialsCopy = JSON.parse(JSON.stringify(data.materialsNeeded));
    const dataCopy = {
      ...data,
      materialsNeeded: materialsCopy,
      blockID: blockID.current,
    };
    setFormData(dataCopy);
    setCurrentLocation(dataCopy.selectedLocation);
    setCurrentLocationRate(
      findCSVRate(selectedOrganization.locationData, dataCopy.selectedLocation)
    );
  };

  // Handle block deletion by blockID
  const handleDeleteBlock = (blockID) => {
    const roomName = currentLocation;
    setReserveBlocks((prevBlocks) => {
      if (!prevBlocks[roomName] || !Array.isArray(prevBlocks[roomName])) {
        return prevBlocks;
      }

      // Filter out the request from the array under the room name
      const updatedRoomRequests = prevBlocks[roomName].filter(
        (request) => request.blockID !== blockID
      );

      // Update the dictionary with the filtered requests for the room
      return {
        ...prevBlocks,
        [roomName]: updatedRoomRequests,
      };
    });
  };

  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);

  // Open Detailed Block
  const handleDetailBlock = (data) => {
    setSelectedReservation(data);
    setShowReservationDetail(true);
    document.body.classList.add("no-scroll");
  };

  // Close Detailed Block
  const closeDetailBlock = () => {
    setSelectedReservation(null);
    setShowReservationDetail(false);
    document.body.classList.remove("no-scroll");
  };

  const [requestedCalendarEvents, setRequestedCalendarEvents] = useState({});
  const eventID = useRef(0);

  // Formats dates to date component {day: X, month: X, year: X}
  function dateComponents(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return {
      year: year,
      month: month,
      day: day,
    };
  }

  // Checks for conflicts
  function checkCalendarConflicts(thisRequest) {
    function checkConflict(thisRequest, otherRequest) {
      const startTime1 = thisRequest.startTime.toDate().getTime();
      const endTime1 = thisRequest.endTime.toDate().getTime();
      const startTime2 = otherRequest.startTime.toDate().getTime();
      const endTime2 = otherRequest.endTime.toDate().getTime();

      const timeOverlap = startTime1 < endTime2 && endTime1 > startTime2;
      const sameLocation =
        thisRequest.selectedLocation === otherRequest.selectedLocation;
      const sameDay =
        thisRequest.datePicked.day === otherRequest.datePicked.day &&
        thisRequest.datePicked.month === otherRequest.datePicked.month &&
        thisRequest.datePicked.year === otherRequest.datePicked.year;

      return sameLocation && timeOverlap && sameDay;
    }

    const relevantEvents = [
      ...(mappedExistingReservations[currentLocation] || []),
      ...(requestedCalendarEvents[currentLocation] || []),
    ];

    const unmappedRelevantEvents = relevantEvents.map(
      (relevantEvent) => relevantEvent.extendedProps
    );

    for (const unmappedRelevantEvent of unmappedRelevantEvents) {
      if (checkConflict(thisRequest, unmappedRelevantEvent)) {
        return true;
      }
    }
    return false;
  }

  // Handle Reservation Blocks in Full Calendar (formats ready for data upload)
  const handleEventReceive = (info) => {
    // Format date and time (time in HH:MM format)
    function mergeDateAndTime(date, time) {
      const [hours, minutes] = time.split(":");
      const mergedDate = new Date(date);
      mergedDate.setHours(hours, minutes, 0, 0);
      return mergedDate;
    }

    const droppedElement = info.draggedEl;
    const extendedProps = JSON.parse(
      droppedElement.getAttribute("data-extended-props")
    );

    const droppedDate = info.date;
    const startTime = mergeDateAndTime(droppedDate, extendedProps.startTime);
    const endTime = mergeDateAndTime(droppedDate, extendedProps.endTime);
    const droppedDateComponents = dateComponents(droppedDate);

    const newEvent = {
      id: eventID.current,
      title: extendedProps.eventName,
      start: startTime,
      end: endTime,
      extendedProps: {
        activity: extendedProps.activity,
        comments: extendedProps.comments,
        cost: extendedProps.cost,
        datePicked: droppedDateComponents,
        endTime: Timestamp.fromDate(endTime),
        eventName: extendedProps.eventName,
        materialsNeeded: extendedProps.materialsNeeded,
        firstName: userData.firstName,
        lastName: userData.lastName,
        organizationId: extendedProps.organizationId,
        paid: extendedProps.cost === 0 ? true : false,
        partySize: extendedProps.partySize,
        selectedLocation: extendedProps.selectedLocation,
        startTime: Timestamp.fromDate(startTime),
        status: "Pending",
        userId: userData.userId,
      },
    };

    if (checkCalendarConflicts(newEvent.extendedProps)) {
      throwError("Conflict with another reservation");
    } else {
      const locationKey = extendedProps.selectedLocation;
      setRequestedCalendarEvents((prevEvents) => {
        const updatedEvents = { ...prevEvents };
        const existingEvents = updatedEvents[locationKey] || [];
        const eventExists = existingEvents.some(
          (event) =>
            event.title === newEvent.title &&
            event.start.getTime() === newEvent.start.getTime()
        );

        if (!eventExists) {
          updatedEvents[locationKey] = [...existingEvents, newEvent];
        }

        return updatedEvents;
      });
      eventID.current++;
    }
  };

  // Handle changes within the calendar upon drag and drop
  const handleEventDrop = (info) => {
    // Merge date and time (time start in DATE data type)
    function mergeDateAndTime(dateStr, timeStr) {
      const mergedDate = new Date(dateStr);
      const hours = timeStr.getHours();
      const minutes = timeStr.getMinutes();
      mergedDate.setHours(hours, minutes, 0, 0);
      return mergedDate;
    }

    const targetID = info.event.id;
    const droppedDate = info.event.start;
    const startTime = mergeDateAndTime(droppedDate, info.event.start);
    const endTime = mergeDateAndTime(droppedDate, info.event.end);
    const droppedDateComponents = dateComponents(droppedDate);

    const locationKey = info.event.extendedProps.selectedLocation;
    const oldEvent = requestedCalendarEvents[locationKey].find(
      (event) => String(event.id) === String(targetID)
    );
    const updatedEvent = {
      ...oldEvent,
      start: startTime,
      end: endTime,
      extendedProps: {
        ...oldEvent.extendedProps,
        datePicked: droppedDateComponents,
        endTime: Timestamp.fromDate(endTime),
        startTime: Timestamp.fromDate(startTime),
      },
    };

    if (checkCalendarConflicts(updatedEvent.extendedProps)) {
      info.revert();
      throwError("Conflict with another reservation");
    } else {
      setRequestedCalendarEvents((prevEvents) => {
        const updatedEvents = requestedCalendarEvents[locationKey].map(
          (event) => {
            if (String(event.id) === String(targetID)) {
              return updatedEvent;
            }
            return event;
          }
        );
        return {
          ...prevEvents,
          [locationKey]: updatedEvents,
        };
      });
    }
  };

  const [showEventDetail, setShowEventDetail] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventDeletable, setEventDeletable] = useState(false);

  // Event click handler
  const handleEventClick = (info) => {
    if (info.event.extendedProps.status === "Pending") {
      setEventDeletable(true);
    }
    setSelectedEvent(info);
    setShowEventDetail(true);
    document.body.classList.add("no-scroll");
  };

  // Handle deleting an event from the calendar
  const handleDeleteEvent = (info) => {
    const targetId = Number(info.event.id);
    setRequestedCalendarEvents((prevEvents) => {
      const updatedEvents = {
        ...prevEvents,
        [currentLocation]: prevEvents[currentLocation].filter(
          (eventObj) => eventObj.id !== targetId
        ),
      };
      return updatedEvents;
    });
  };

  // Close Event Details
  const closeEventDetails = () => {
    setSelectedEvent(null);
    setShowEventDetail(false);
    setEventDeletable(false);
    document.body.classList.remove("no-scroll");
  };

  const [addedLocationsList, setAddedLocationsList] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");

  // Previous Location
  const handlePrevLocation = () => {
    setCurrentLocation((prevLocation) => {
      const currentIndex = addedLocationsList.indexOf(prevLocation);
      const newIndex =
        (currentIndex - 1 + addedLocationsList.length) %
        addedLocationsList.length;
      return addedLocationsList[newIndex];
    });
  };

  // Next Location
  const handleNextLocation = () => {
    setCurrentLocation((prevLocation) => {
      const currentIndex = addedLocationsList.indexOf(prevLocation);
      const newIndex = (currentIndex + 1) % addedLocationsList.length;
      return addedLocationsList[newIndex];
    });
  };

  // Load reservations based on addedLocationsList
  useEffect(() => {
    // Map existing reservations to full calendar friendly format
    function mapExistingReservations(reservations) {
      const mappedExistingReservations = {};
      for (const event of reservations) {
        const mappedExistingEvent = {
          classNames: ["Others"],
          id: event.reservationId,
          title: event.eventName,
          start: event.startTime.toDate(),
          end: event.endTime.toDate(),
          editable: false,
          extendedProps: {
            activity: event.activity,
            comments: event.comments,
            cost: event.cost,
            datePicked: event.datePicked,
            endTime: event.endTime,
            eventName: event.eventName,
            materialsNeeded: event.materialsNeeded,
            name: event.name,
            organizationId: event.organizationId,
            paid: event.paid,
            partySize: event.partySize,
            reservationId: event.reservationId,
            selectedLocation: event.selectedLocation,
            startTime: event.startTime,
            status: event.status,
            userId: event.userId,
          },
        };
        if (!mappedExistingReservations[event.selectedLocation]) {
          mappedExistingReservations[event.selectedLocation] = [];
        }
        mappedExistingReservations[event.selectedLocation].push(
          mappedExistingEvent
        );
      }
      return mappedExistingReservations;
    }

    const loadReservations = async () => {
      try {
        const unpopulatedLocations = addedLocationsList.filter(
          (location) =>
            !mappedExistingReservations[location] ||
            mappedExistingReservations[location].length === 0
        );

        if (unpopulatedLocations.length === 0) return;

        const organizationReservations = await fetchOrganizationReservations(
          {
            status: ["Approved"],
            queryLocations: unpopulatedLocations,
          },
          selectedOrganization.organizationId
        );

        if (organizationReservations) {
          setMappedExistingReservations((prevReservations) => ({
            ...prevReservations,
            ...mapExistingReservations(organizationReservations),
          }));
        }
      } catch (error) {
        throwError("Error loading reservations:", error);
      }
    };

    loadReservations();
  }, [addedLocationsList]);

  const [displayedCalendarEvents, setDisplayedCalendarEvents] = useState([]);

  // Set the calendar's events based on existing reservations and requested ones
  useEffect(() => {
    setDisplayedCalendarEvents([
      ...(mappedExistingReservations[currentLocation] || []),
      ...(requestedCalendarEvents[currentLocation] || []),
    ]);
  }, [
    currentLocation,
    requestedCalendarEvents,
    mappedExistingReservations,
    addedLocationsList,
  ]);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState(null);
  const [resolveConfirmation, setResolveConfirmation] = useState(null);

  // Show Confirmation Popup
  const handleConfirmationPopup = (content) => {
    setConfirmationContent(content);
    setShowConfirmationPopup(true);
    document.body.classList.add("no-scroll");

    return new Promise((resolve) => {
      setResolveConfirmation(() => resolve);
    });
  };

  // Close Confirmation Popup
  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
    setConfirmationContent(null);
    document.body.classList.remove("no-scroll");
  };

  // Confirmation handling logic (confirm)
  const handleConfirmPopup = () => {
    handleCloseConfirmationPopup();
    if (resolveConfirmation) resolveConfirmation(true);
  };

  // Confirmation handling logic (cancel)
  const handleCancelPopup = () => {
    handleCloseConfirmationPopup();
    if (resolveConfirmation) resolveConfirmation(false);
  };

  const [showReservationsSent, setShowReservationsSent] = useState(false);
  const [reservationsSentContent, setReservationsSentContent] = useState(null);

  const handleReservationsSent = (content) => {
    setReservationsSentContent(content);
    setShowReservationsSent(true);
    document.body.classList.add("no-scroll");
  };

  const closeReservationsSent = () => {
    setShowReservationsSent(false);
    setReservationsSentContent(null);
    document.body.classList.remove("no-scroll");
  };

  // Handle submitting all reservations
  const handleSubmitAll = async () => {
    const allRequests = Object.values(requestedCalendarEvents).flat();
    const unmappedRequests = allRequests.map((event) => {
      return event.extendedProps;
    });
    const confirmSwitch = await handleConfirmationPopup({
      mainMessage: "Do you want to request the following event(s)?",
      childComponent: <ReservationsItemized reservations={unmappedRequests} />,
    });

    if (!confirmSwitch) {
      return;
    }

    setMessage("Submitting Requests...");
    setLoading(true);
    await Promise.all(
      unmappedRequests.map((request) => createReservation(request))
    );
    setMessage("");
    setLoading(false);
    cleanupVariables();

    handleReservationsSent({
      mainMessage: "Your request has been sent!",
      subMessage: "An admin will review it shortly.",
    });
  };

  // Reset variables
  const cleanupVariables = () => {
    setLoading(false);
    setMessage("");
    clearError();
    setMappedExistingReservations({});
    setFormData({
      activity: "",
      selectedLocation: "",
      eventName: "",
      partySize: "",
      startTime: "",
      endTime: "",
      materialsNeeded: [],
      comments: "",
      cost: 0,
    });
    setSelectedOrganization(null);
    setShowOrganizationSelect(false);
    setLocationSelect(false);
    setCurrentLocationRate(0);
    setShowMaterialsSelect(false);
    setReserveBlocks({});
    blockID.current = 0;
    setShowReservationDetail(false);
    setSelectedReservation(null);
    setRequestedCalendarEvents({});
    eventID.current = 0;
    setShowEventDetail(false);
    setEventDeletable(false);
    setSelectedEvent(null);
    setAddedLocationsList([]);
    setCurrentLocation("");
    setDisplayedCalendarEvents([]);
    setShowConfirmationPopup(false);
    setConfirmationContent(null);
    setResolveConfirmation(null);
  };

  return (
    <div className="safe-area">
      <div className="reserve-page">
        <ReserveCreate
          formData={formData}
          onChangeFormData={setFormData}
          onSubmit={handleReservationSubmit}
          selectedOrganization={selectedOrganization}
          onOrganizationSelect={handleOrganizationSelect}
          onLocationSelect={handleLocationSelect}
          onMaterialsSelect={handleMaterialsSelect}
          onPlusMaterial={handleAddMaterial}
          onMinusMaterial={handleSubtractMaterial}
          onRemoveMaterial={handleRemoveMaterial}
        />

        <div className="reserve-build">
          <div className="reserve-build-header">
            {addedLocationsList.length > 1 ? (
              <button onClick={handlePrevLocation}>
                <h2>
                  <i className="fas fa-chevron-left"></i>
                </h2>
              </button>
            ) : null}

            <h2 className="title">
              {addedLocationsList.length > 0
                ? currentLocation
                : "No Location Selected"}
            </h2>

            {addedLocationsList.length > 1 ? (
              <button onClick={handleNextLocation}>
                <h2>
                  <i className="fas fa-chevron-right"></i>
                </h2>
              </button>
            ) : null}
          </div>

          <div className="reserve-calendar">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "title",
                right: "today prev,next",
              }}
              events={displayedCalendarEvents}
              slotEventOverlap={false}
              expandRows={true}
              editable={true}
              droppable={true}
              drop={handleEventReceive}
              eventDrop={(info) => handleEventDrop(info)}
              eventClassNames={"reserve-event"}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              }}
              eventClick={handleEventClick}
              dropAccept=".reserve-block"
            />
          </div>

          <div className="reserve-blocks">
            <div className="reserve-blocks-row" ref={reserveBlockContainerRef}>
              {reserveBlocks[currentLocation] &&
              reserveBlocks[currentLocation].length > 0 ? (
                reserveBlocks[currentLocation].map((block) => (
                  <ReserveBlock
                    key={block.blockID}
                    data={block}
                    onCopy={handleCopyBlock}
                    onDetails={handleDetailBlock}
                    onDelete={handleDeleteBlock}
                  />
                ))
              ) : (
                <p className="reserve-block-empty">
                  Reservation Blocks will appear here
                </p>
              )}
            </div>
          </div>

          <button
            className="reserve-final-button"
            onClick={handleSubmitAll}
            disabled={
              Object.values(requestedCalendarEvents).flat().length === 0
            }
          >
            <h4>Submit Reservation Requests</h4>
          </button>
        </div>

        {showOrganizationSelect ? (
          <OrganizationSelect
            onClose={closeOrganizationSelect}
            organizationsList={userData.organizations}
            currentSelectedOrganization={selectedOrganization}
            onSelectOrganization={handleSelectOrganization}
          />
        ) : null}

        {showLocationSelect ? (
          <LocationSelect
            formData={formData}
            locationData={selectedOrganization.locationData}
            onSelectLocation={handleSelectLocation}
            onClose={closeLocationSelect}
          />
        ) : null}

        {showMaterialsSelect ? (
          <MaterialsSelect
            formData={formData}
            materialData={selectedOrganization.materialsData}
            onPlus={handleAddMaterial}
            onRemove={handleRemoveMaterial}
            onClose={closeMaterialsSelect}
          />
        ) : null}

        {showReservationDetail ? (
          <ReserveBlockDetail
            reservation={selectedReservation}
            onClose={closeDetailBlock}
          />
        ) : null}

        {showEventDetail ? (
          <ReservationDetail
            reservation={selectedEvent.event.extendedProps}
            onClose={closeEventDetails}
            editable={false}
            onDelete={
              eventDeletable ? () => handleDeleteEvent(selectedEvent) : null
            }
          />
        ) : null}

        {showConfirmationPopup ? (
          <ConfirmationPopup
            content={confirmationContent}
            onClose={handleCancelPopup}
            onConfirm={handleConfirmPopup}
            onCancel={handleCancelPopup}
          />
        ) : null}

        {showReservationsSent ? (
          <ConfirmationPopupLite
            content={reservationsSentContent}
            onClose={closeReservationsSent}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Reserve;
