import React, { createContext, useContext, useState } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  // Function to set an error message
  const throwError = (message) => {
    setError(message);
  };

  // Function to clear the error
  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, throwError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
