import React, { createContext, useContext, useState } from "react";

// Create context
const LoadingContext = createContext();

// Provide context
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const setLoading = (value) => setIsLoading(value);

  return (
    <LoadingContext.Provider
      value={{ isLoading, setLoading, message, setMessage }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
