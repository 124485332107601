import React from "react";
import { Link } from "react-router-dom";
import "./Display.css";
import ReservationDisplay from "../components/ReservationDisplay";

function Display() {
  const reservationsThisHour = [
    {
      id: 1,
      activity: "Athletics",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Basketball",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T13:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 2,
      activity: "Clubs",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Swimming",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T14:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 3,
      activity: "Renters",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Football",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T15:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 4,
      activity: "Others",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:16:40-05:00",
      eventName: "Varsity Lacrosse Match",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T17:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 5,
      activity: "Athletics",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName:
        "Varsity SUPER DUPER LONG NAME NAME NAME NAME NAME NAME NAME NAME NAME NAME NAME",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T17:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
  ];

  const upcomingReservations = [
    {
      id: 1,
      activity: "Athletics",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Basketball",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T13:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 2,
      activity: "Clubs",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Swimming",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T14:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 3,
      activity: "Renters",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName: "Varsity Football",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T15:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 4,
      activity: "Others",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:16:40-05:00",
      eventName: "Varsity Lacrosse Match",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T17:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
    {
      id: 5,
      activity: "Athletics",
      comments: "Do this and this and this",
      cost: 0,
      datePicked: { day: 10, month: 9, year: 2024 },
      endTime: "2024-09-09T20:10:40-05:00",
      eventName:
        "Varsity SUPER DUPER LONG NAME NAME NAME NAME NAME NAME NAME NAME NAME NAME NAME",
      materialsNeeded: [
        { cost: 0, count: 1, name: "LCD Projector" },
        { cost: 0, count: 1, name: "Sound System - Athletics" },
      ],
      name: "Darren",
      organizationId: "TeqhUtuHEvaWp8sUGThh",
      paid: true,
      partySize: "12",
      selectedLocation: "Activity Center",
      startTime: "2024-09-09T17:00:35-05:00",
      status: "Approved",
      userId: "vkgVC4e2HRcdrWXjgugnH6ROrEi2",
    },
  ];

  return (
    <div className="safe-area-ignore-sidebar">
      <div className="display-page">
        <Link to="/home" className="display-escape-arrow">
          <h1>&#x2190;</h1>
        </Link>
        <div className="display-reservations">
          <div className="display-now">
            <h2>Ongoing Events</h2>
            {reservationsThisHour.map((reservation) => (
              <ReservationDisplay reservation={reservation} />
            ))}
          </div>
          <div className="display-upcoming">
            <h2>Upcoming Events</h2>
            {upcomingReservations.map((reservation) => (
              <ReservationDisplay reservation={reservation} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Display;
