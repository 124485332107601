import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useData } from "../context/DataContext";

import "./Sidebar.css";
import logo from "../images/logo-gray.png";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const { userData, logOutUser } = useData();

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="pages">
        <div className="menu-divider" />
        <ul>
          <li>
            <h3>
              <Link
                to="/home"
                className={location.pathname === "/home" ? "active-link" : ""}
              >
                Home
              </Link>
            </h3>
          </li>
          <li>
            <h3>
              <Link
                to="/reserve"
                className={
                  location.pathname === "/reserve" ? "active-link" : ""
                }
              >
                Reserve
              </Link>
            </h3>
          </li>
          <li>
            <h3>
              <Link
                to="/calendar"
                className={
                  location.pathname === "/calendar" ? "active-link" : ""
                }
              >
                Calendar
              </Link>
            </h3>
          </li>
          {/* <li>
          <h3>
            <Link
              to="/payment"
              className={location.pathname === "/payment" ? "active-link" : ""}
            >
              Payment
            </Link>
          </h3>
        </li> */}
        </ul>
      </div>

      {userData && userData.adminIds && userData.adminIds.length > 0 ? (
        <div className="admin-pages">
          <div className="menu-divider" />
          <ul>
            <li>
              <h3>
                <Link
                  to="/requests"
                  className={
                    location.pathname === "/requests" ? "active-link" : ""
                  }
                >
                  Requests
                </Link>
              </h3>
            </li>
            <li>
              <h3>
                <Link
                  to="/display"
                  className={
                    location.pathname === "/display" ? "active-link" : ""
                  }
                >
                  Display
                </Link>
              </h3>
            </li>
          </ul>
        </div>
      ) : null}

      <div className="logout-container">
        <button className="logout-button" onClick={logOutUser}>
          Log Out
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
