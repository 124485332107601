import React, { useState, useMemo } from "react";

import "./Home.css";
import ReservationCard from "../components/HomeComponents/ReservationCard";
import ReservationDetail from "../components/Popups/ReservationDetail";

import { useData } from "../context/DataContext";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";
import NotificationsBar from "../components/HomeComponents/NotificationsBar";

const Home = () => {
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();
  const { userData, setUserData, fetchUserNotifications } = useData();

  const approvedReservations = useMemo(() => {
    if (!userData.reservations) {
      return [];
    }
    return userData.reservations.filter((r) => r.status === "Approved");
  }, [userData.reservations]);

  const pendingReservations = useMemo(() => {
    if (!userData.reservations) {
      return [];
    }
    return userData.reservations.filter((r) => r.status === "Pending");
  }, [userData.reservations]);

  const rejectedReservations = useMemo(() => {
    if (!userData.reservations) {
      return [];
    }
    return userData.reservations.filter((r) => r.status === "Rejected");
  }, [userData.reservations]);

  const [showAllApproved, setShowAllApproved] = useState(false);
  const [showAllPending, setShowAllPending] = useState(false);
  const [showAllRejected, setShowAllRejected] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  // Show Approved Reservations (toggle between 3 or All)
  const displayedApprovedReservations = showAllApproved
    ? approvedReservations
    : approvedReservations.slice(0, 5);

  // Show Pending Reservations (toggle between 3 or All)
  const displayedPendingReservations = showAllPending
    ? pendingReservations
    : pendingReservations.slice(0, 5);

  // Show Rejected Reservations (toggle between 3 or All)
  const displayedRejectedReservations = showAllRejected
    ? rejectedReservations
    : rejectedReservations.slice(0, 5);

  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [reservationDetailEditable, setReservationDetailEditable] =
    useState(true);

  // Open Reservation Details
  const handleDetailClick = (reservation, isEditable) => {
    setSelectedReservation(reservation);
    setShowReservationDetail(true);
    setReservationDetailEditable(isEditable);
    document.body.classList.add("no-scroll");
  };

  // Close Reservation Details
  const closeReservationDetails = () => {
    setSelectedReservation(null);
    setShowReservationDetail(false);
    document.body.classList.remove("no-scroll");
  };

  // Open notifications bar
  const handleNotificationsBar = async () => {
    if (userData.notifications) {
      setShowNotifications(true);
      document.body.classList.add("no-scroll");
    } else {
      setLoading(true);
      setMessage("Loading notifications...");
      try {
        const notifications = await fetchUserNotifications(userData.userId);
        if (notifications) {
          setUserData((prevData) => ({
            ...prevData,
            notifications: notifications,
          }));
        }
        setLoading(false);
        setMessage("");
        setShowNotifications(true);
        document.body.classList.add("no-scroll");
      } catch (error) {
        throwError(`Error loading notifications ${error}`);
        setLoading(false);
        setMessage("");
      }
    }
  };

  // Close notifications bar
  const closeNotificationsBar = () => {
    setShowNotifications(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <>
      <div className="safe-area">
        <div className="home">
          <div className="home-header">
            <h1>Welcome {userData && userData.firstName}</h1>

            <div className="header-buttons">
              <button onClick={() => handleNotificationsBar()}>
                <h1>
                  <i className="fa-solid fa-bell"></i>
                </h1>
              </button>

              {/* <button>
                <h1>
                  <i className="fa-solid fa-gear"></i>
                </h1>
              </button> */}
            </div>
          </div>

          <div className="home-columns">
            <div className="reservation-column">
              <div className="upcoming-container">
                <div className="section-header">
                  <h2>Upcoming Reservations</h2>
                  <button
                    className="show-all"
                    onClick={() => setShowAllApproved(!showAllApproved)}
                  >
                    <h2>{showAllApproved ? "Show Less >" : "Show All >"}</h2>
                  </button>
                </div>
                <div className="reservation-cards-container">
                  {displayedApprovedReservations.length > 0 ? (
                    displayedApprovedReservations.map((reservation) => (
                      <ReservationCard
                        key={reservation.id}
                        reservation={reservation}
                        onClick={() => handleDetailClick(reservation, true)}
                      />
                    ))
                  ) : (
                    <div className="empty-reservation-card">
                      No approved reservations
                    </div>
                  )}
                </div>
              </div>

              <div className="pending-container">
                <div className="section-header">
                  <h2>Pending Reservations</h2>
                  <button
                    className="show-all"
                    onClick={() => setShowAllPending(!showAllPending)}
                  >
                    <h2>{showAllPending ? "Show Less >" : "Show All >"}</h2>
                  </button>
                </div>
                <div className="reservation-cards-container">
                  {displayedPendingReservations.length > 0 ? (
                    displayedPendingReservations.map((reservation) => (
                      <ReservationCard
                        key={reservation.id}
                        reservation={reservation}
                        onClick={() => handleDetailClick(reservation, true)}
                      />
                    ))
                  ) : (
                    <div className="empty-reservation-card">
                      No pending reservations
                    </div>
                  )}
                </div>
              </div>

              <div className="rejected-container">
                <div className="section-header">
                  <h2>Rejected Reservations</h2>
                  <button
                    className="show-all"
                    onClick={() => setShowAllRejected(!showAllRejected)}
                  >
                    <h2>{showAllRejected ? "Show Less >" : "Show All >"}</h2>
                  </button>
                </div>
                <div className="reservation-cards-container">
                  {displayedRejectedReservations.length > 0 ? (
                    displayedRejectedReservations.map((reservation) => (
                      <ReservationCard
                        key={reservation.id}
                        reservation={reservation}
                        onClick={() => handleDetailClick(reservation, true)}
                      />
                    ))
                  ) : (
                    <div className="empty-reservation-card">
                      No rejected reservations
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {showNotifications ? (
            <NotificationsBar
              notifications={userData.notifications}
              onClose={closeNotificationsBar}
            />
          ) : null}

          {showReservationDetail ? (
            <ReservationDetail
              reservation={selectedReservation}
              onClose={closeReservationDetails}
              editable={reservationDetailEditable}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Home;
