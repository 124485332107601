import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAVBPYI7utXR_ssMVhh8hQamQxIpfY2ios",
    authDomain: "bookr-26c82.firebaseapp.com",
    databaseURL: "https://bookr-26c82-default-rtdb.firebaseio.com",
    projectId: "bookr-26c82",
    storageBucket: "bookr-26c82.appspot.com",
    messagingSenderId: "43213792033",
    appId: "1:43213792033:web:ccd92a3b80040c19f79460",
    measurementId: "G-RFC0PES59C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
