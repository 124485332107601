import React from "react";
import "./ReservationDisplay.css";

const ReservationDisplay = ({ reservation }) => {
  // Convert Time to String
  const formatTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amPm}`;
  };

  return (
    <div className="reservation-display">
      <div className="horizontal-align">
        <h3>{reservation.eventName}</h3>
        <h3 className="display-time">
          {formatTime(reservation.startTime)}-{formatTime(reservation.endTime)}
        </h3>
      </div>
      <h3 className="display-location">{reservation.selectedLocation}</h3>
    </div>
  );
};

export default ReservationDisplay;
