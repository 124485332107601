import React, { useState } from "react";
import "./Login.css";
import logo from "../images/logo-square.png";

import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { auth } from "../firebase";
import { useData } from "../context/DataContext";
import { useLoading } from "../context/LoadingContext";

const Login = () => {
  const { setLoading, setMessage } = useLoading();
  const { logInUser } = useData();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("Logging in...");
    setLoading(true);
    try {
      const persistence = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await logInUser(user.uid);
    } catch (error) {
      console.error("Login error:", error);
      setError("Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
      setMessage("");
    }
  };

  return (
    <div className="login-page">
      <div className="login-content">
        <div className="login-header">
          <img src={logo} alt="logo" />
          <h2>Log In</h2>
        </div>

        <form onSubmit={handleLogin}>
          <div className="auth-info">
            <div className="email">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(t) => setEmail(t.target.value)}
                autoComplete="off"
                required
              />
            </div>

            <div className="password">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(t) => setPassword(t.target.value)}
                autoComplete="off"
                required
              />

              <button
                type="button"
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                {showPassword ? (
                  <i className="fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="fa-solid fa-eye"></i>
                )}
              </button>
            </div>

            <div className="remember-me">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              Remember me
            </div>
          </div>

          {error && <p>{error}</p>}

          <button className="login-button" type="submit">
            <h3>Log In</h3>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
