import React from "react";
import "./Notification.css";

const Notification = ({ notification }) => {
  function formatTimestamp(timestamp) {
    const milliseconds =
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6);
    const date = new Date(milliseconds);
    const month = (date.getMonth() + 1).toString().padStart(2);
    const day = date.getDate().toString().padStart(2);
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  }

  return (
    <>
      <div className="notification">
        <button>
          <div className="notification-header">
            <h3>{notification.subject}</h3>
            <h4>{formatTimestamp(notification.timestamp)}</h4>
          </div>
          <p>{notification.text}</p>
        </button>
      </div>
    </>
  );
};

export default Notification;
