import React, { useState } from "react";
import "./ReserveCreate.css";

import MaterialsChip from "./MaterialsChip";

const ReserveCreate = ({
  formData,
  onChangeFormData,
  onSubmit,
  selectedOrganization,
  onOrganizationSelect,
  onLocationSelect,
  onMaterialsSelect,
  onPlusMaterial,
  onMinusMaterial,
  onRemoveMaterial,
}) => {
  const [error, setError] = useState("");

  // Update form data via the onChangeFormData prop
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChangeFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const {
      activity,
      selectedLocation,
      eventName,
      partySize,
      startTime,
      endTime,
    } = formData;
    if (!activity) {
      setError("Please enter an activity");
      return;
    }
    if (!selectedLocation) {
      setError("Please enter a location");
      return;
    }
    if (!eventName) {
      setError("Please enter an event name");
      return;
    }
    if (!partySize) {
      setError("Please enter a party size");
      return;
    }
    if (!startTime) {
      setError("Please enter a start time");
      return;
    }
    if (!endTime) {
      setError("Please enter an end time");
      return;
    }

    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    if (start >= end) {
      setError("Start time must be before end time");
      return;
    }
    setError("");
    onSubmit(formData);
  };

  // Convert Cost Int to String
  function formatCost(cost) {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  }

  return (
    <form className="reserve-create" onSubmit={handleFormSubmit}>
      <h2>Reserve</h2>

      <div className="reserve-organization-select">
        <label htmlFor="organizationId">Organization</label>
        <button
          type="button"
          name="organizationId"
          id="organizationId"
          onClick={onOrganizationSelect}
        >
          {selectedOrganization
            ? selectedOrganization.name
            : "Select an Organization"}
        </button>
      </div>

      <div className="reserve-activity">
        <label htmlFor="activity">Activity</label>
        <select
          name="activity"
          id="activity"
          value={formData.activity}
          onChange={handleChange}
          disabled={!selectedOrganization}
        >
          <option value="">Select an activity</option>
          <option value="Athletics">Athletics</option>
          <option value="Clubs">Clubs</option>
          <option value="Renters">Renters</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="reserve-selected-location">
        <label htmlFor="selectedLocation">Location</label>
        <button
          type="button"
          name="selectedLocation"
          id="selectedLocation"
          value={formData.selectedLocation}
          onClick={onLocationSelect}
          disabled={!selectedOrganization}
        >
          {formData.selectedLocation === ""
            ? "Select a Location"
            : formData.selectedLocation}
        </button>
      </div>

      <div className="reserve-event-name">
        <label htmlFor="eventName">Event</label>
        <input
          type="text"
          name="eventName"
          id="eventName"
          value={formData.eventName}
          onChange={handleChange}
          disabled={!selectedOrganization}
        />
      </div>

      <div className="reserve-short-fields">
        <div className="reserve-party-size">
          <label htmlFor="partySize">Party Size</label>
          <input
            type="number"
            min="0"
            name="partySize"
            id="partySize"
            value={formData.partySize}
            onChange={handleChange}
            disabled={!selectedOrganization}
          />
        </div>
        <div className="reserve-start-time">
          <label htmlFor="startTime">Start Time</label>
          <input
            type="time"
            name="startTime"
            id="startTime"
            value={formData.startTime}
            onChange={handleChange}
            disabled={!selectedOrganization}
          />
        </div>

        <div className="reserve-end-time">
          <label htmlFor="endTime">End Time</label>
          <input
            type="time"
            name="endTime"
            id="endTime"
            value={formData.endTime}
            onChange={handleChange}
            disabled={!selectedOrganization}
          />
        </div>
      </div>

      <div className="reserve-materials-needed">
        <div className="reserve-materials-header">
          <label htmlFor="materialsNeeded">Materials Needed</label>
          <button
            type="button"
            onClick={onMaterialsSelect}
            disabled={!selectedOrganization}
          >
            <i className="fas fa-plus"></i> Add Materials
          </button>
        </div>

        <div className="reserve-materials-row">
          {formData.materialsNeeded.length > 0 ? (
            formData.materialsNeeded.map((material) => (
              <MaterialsChip
                material={material}
                onPlusMaterial={onPlusMaterial}
                onMinusMaterial={onMinusMaterial}
                onRemoveMaterial={onRemoveMaterial}
              />
            ))
          ) : (
            <div className="empty-materials">No Materials</div>
          )}
        </div>
      </div>

      <div className="reserve-comments">
        <label htmlFor="comments">Comments</label>
        <textarea
          name="comments"
          id="comments"
          value={formData.comments}
          onChange={handleChange}
          disabled={!selectedOrganization}
        ></textarea>
      </div>

      <div className="reserve-estimated-costs">
        <label htmlFor="estimated-costs">
          Estimated Cost: {formatCost(formData.cost)}
        </label>
      </div>

      {error && <div className="error-message">* {error}</div>}

      <div className="reserve-submit">
        <button type="submit" disabled={!selectedOrganization}>
          Create Reservation Block
        </button>
      </div>
    </form>
  );
};

export default ReserveCreate;
