import { React, useState } from "react";
import "./LocationSelect.css";

const LocationSelect = ({
  formData,
  locationData,
  onSelectLocation,
  onClose,
}) => {
  // Convert Cost Int to String
  function formatCost(cost) {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  }

  // State for filter input
  const [filter, setFilter] = useState("");

  // Handler to update the filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter the locations based on the input
  function filterLocations() {
    // Recursive function to flatten the nested dictionary
    function flattenLocations(locations, type = null) {
      let result = [];
      for (const [key, value] of Object.entries(locations)) {
        if (typeof value === "object" && !Array.isArray(value)) {
          result = [...result, ...flattenLocations(value, key)];
        } else {
          result.push({
            name: key,
            cost: value,
            type,
          });
        }
      }

      return result;
    }

    const mappedLocations = flattenLocations(locationData);
    const filteredLocations = mappedLocations.filter((location) =>
      location.name.toLowerCase().includes(filter.toLowerCase())
    );
    if (filteredLocations.length > 0) {
      return filteredLocations.map((location) => (
        <LocationCard key={location.name} location={location} depth={0} />
      ));
    } else {
      return <p className="location-empty">No Locations</p>;
    }
  }

  const [expandedCategories, setExpandedCategories] = useState({});

  // Toggles Expanded Categories
  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Categorize Locations
  function categorizeLocations(dict, depth) {
    return Object.entries(dict).map(([key, value]) => {
      if (typeof value === "object") {
        return (
          <LocationCategory
            key={key}
            category={key}
            children={value}
            depth={depth}
            expanded={expandedCategories[key]}
            onToggle={toggleCategory}
          />
        );
      } else {
        return (
          <LocationCard
            key={key}
            location={{ name: key, cost: value }}
            depth={depth}
          />
        );
      }
    });
  }

  const LocationCard = ({ location, depth }) => {
    const alreadySelected = formData.selectedLocation === location.name;

    return (
      <button
        className={`location-card ${alreadySelected ? "selected" : null}`}
        style={{ "--depth": depth }}
        onClick={() => onSelectLocation(location)}
      >
        <h3>
          {location.name}: {formatCost(location.cost)}
          {location.cost === 0 ? "" : "/hr"}
          <i className="fas fa-plus"></i>
        </h3>
      </button>
    );
  };

  const LocationCategory = ({
    category,
    children,
    depth,
    expanded,
    onToggle,
  }) => {
    return (
      <>
        <button
          className={"location-category"}
          style={{ "--depth": depth }}
          onClick={() => onToggle(category)}
        >
          <h3>
            {category}
            <i
              className={
                expanded ? "fas fa-chevron-down" : "fas fa-chevron-right"
              }
            ></i>
          </h3>
        </button>

        {expanded ? categorizeLocations(children, depth + 1) : null}
      </>
    );
  };

  return (
    <div className="location-select-overlay" onClick={onClose}>
      <div className="location-select" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h3>Select a Location</h3>
        <div className="location-search">
          <input
            type="text"
            name="eventName"
            id="eventName"
            value={filter}
            placeholder="Search for a location"
            onChange={handleFilterChange}
          />
          <i className="fas fa-search"></i>
        </div>
        <div className="location-list">
          {filter === ""
            ? categorizeLocations(locationData, 0)
            : filterLocations()}
        </div>
      </div>
    </div>
  );
};

export default LocationSelect;
