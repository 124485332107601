import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import ReservationDetail from "../components/Popups/ReservationDetail";
import OrganizationSelect from "../components/Popups/OrganizationSelect";

import { useData } from "../context/DataContext";
import { useLoading } from "../context/LoadingContext";
import { useError } from "../context/ErrorContext";

const Calendar = () => {
  const { setLoading, setMessage } = useLoading();
  const { throwError, clearError } = useError();
  const { userData, fetchOrganizationReservations } = useData();

  const loadOrganizationData = async (organizationId) => {
    setLoading(true);
    setMessage("Loading organization data...");
    try {
      const organizationReservations = await fetchOrganizationReservations(
        { status: ["Approved"] },
        organizationId
      );
      if (organizationReservations) {
        const mappedReservations = organizationReservations.map((event) => ({
          classNames: [event.activity],
          id: event.reservationId,
          title: event.eventName,
          start: event.startTime.toDate(),
          end: event.endTime.toDate(),
          extendedProps: {
            activity: event.activity,
            comments: event.comments,
            cost: event.cost,
            datePicked: event.datePicked,
            endTime: event.endTime,
            eventName: event.eventName,
            materialsNeeded: event.materialsNeeded,
            firstName: event.firstname,
            lastName: event.lastName,
            organizationId: event.organizationId,
            paid: event.paid,
            partySize: event.partySize,
            reservationId: event.reservationId,
            selectedLocation: event.selectedLocation,
            startTime: event.startTime,
            status: event.status,
            userId: event.userId,
          },
        }));
        setExistingReservations(mappedReservations);
      }
      setLoading(false);
      setMessage("");
    } catch (error) {
      throwError(`Error loading organization data ${error}`);
      setLoading(false);
      setMessage("");
    }
  };

  // Map custom events to FullCalendar's format
  const [existingReservations, setExistingReservations] = useState([]);

  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);

  // Event click handler
  const handleEventClick = (info) => {
    setSelectedReservation(info.event.extendedProps);
    setShowReservationDetail(true);
    document.body.classList.add("no-scroll");
  };

  // Close Reservation Details
  const closeReservationDetails = () => {
    setSelectedReservation(null);
    setShowReservationDetail(false);
    document.body.classList.remove("no-scroll");
  };

  const [filter, setFilter] = useState("");

  // Handler to update the filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Search for an event based on its name
  const filteredEvents = existingReservations.filter((event) =>
    event.extendedProps.eventName.toLowerCase().includes(filter.toLowerCase())
  );

  // Convert Date Dictionary to String
  const formatDate = (datePicked) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[datePicked.month - 1]} ${datePicked.day}, ${
      datePicked.year
    }`;
  };

  const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // Organization click handler
  const handleOrganizationSelect = () => {
    setShowOrganizationSelect(true);
    document.body.classList.add("no-scroll");
  };

  // Set selectedOrganization
  const handleSelectOrganization = async (organization) => {
    setSelectedOrganization(organization);
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
    await loadOrganizationData(organization.organizationId);
  };

  // Close Organization Details
  const closeOrganizationSelect = () => {
    setShowOrganizationSelect(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div className="safe-area">
      <div className="calendar-page">
        <div className="calendar-container">
          <div className="calendar-header">
            <button onClick={handleOrganizationSelect}>
              <h3>
                {selectedOrganization
                  ? selectedOrganization.name
                  : "No Selected Organization"}{" "}
                <i className="fas fa-chevron-right"></i>
              </h3>
            </button>
          </div>

          <div className="calendar-box">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "dayGridMonth,timeGridWeek,timeGridDay",
                center: "title",
                right: "today prev,next",
              }}
              events={existingReservations}
              slotEventOverlap={false}
              slotMinTime="05:00:00"
              slotMaxTime="23:00:00"
              eventClick={handleEventClick}
              expandRows={true}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              }}
              height="100%"
            />
          </div>
        </div>

        <div className="search-column">
          <h2>Search</h2>
          <div className="events-search">
            <input
              type="text"
              name="search"
              id="search"
              value={filter}
              placeholder="Search for an event"
              onChange={handleFilterChange}
            />
            <i className="fas fa-search"></i>
          </div>
          <div className="filtered-events-container">
            {filter ? (
              filteredEvents.length > 0 ? (
                filteredEvents.map((event) => (
                  <div className="filtered-events">
                    <h4 className="filtered-event-name">
                      {event.extendedProps.eventName}
                    </h4>
                    <h4 className="filtered-event-date">
                      {formatDate(event.extendedProps.datePicked)}
                    </h4>
                  </div>
                ))
              ) : (
                <p className="filtered-events-empty">
                  No Events Matching The Filter
                </p>
              )
            ) : null}
          </div>
        </div>

        {showReservationDetail ? (
          <ReservationDetail
            reservation={selectedReservation}
            onClose={closeReservationDetails}
            editable={false}
          />
        ) : null}

        {showOrganizationSelect ? (
          <OrganizationSelect
            onClose={closeOrganizationSelect}
            organizationsList={userData.organizations}
            currentSelectedOrganization={selectedOrganization}
            onSelectOrganization={handleSelectOrganization}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Calendar;
