import React from "react";
import "./ReserveBlock.css";

const ReserveBlock = ({ data, onCopy, onDetails, onDelete }) => {
  function formatTimeTo12Hour(time24) {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 === 0 ? 12 : hours % 12;
    return `${hours12}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
  }

  return (
    <div
      className="reserve-block"
      draggable={true}
      data-title={data.eventName}
      data-extended-props={JSON.stringify(data)}
    >
      <div className="reserve-block-buttons">
        <button title="Block Details" onClick={() => onDetails(data)}>
          <h3>
            <i className="fa-solid fa-circle-info"></i>
          </h3>
        </button>
        <button title="Copy Block" onClick={() => onCopy(data)}>
          <h3>
            <i className="fas fa-plus"></i>
          </h3>
        </button>
        <button title="Delete Block" onClick={() => onDelete(data.blockID)}>
          <h3>
            <i className="fas fa-times"></i>
          </h3>
        </button>
      </div>
      <div className="reserve-block-info">
        <h3>{data.eventName}</h3>
        <p>{data.selectedLocation}</p>
        <p>
          {formatTimeTo12Hour(data.startTime)} -{" "}
          {formatTimeTo12Hour(data.endTime)}
        </p>
        {data.materialsNeeded && data.materialsNeeded.length > 0 ? (
          <p>Needs Materials</p>
        ) : (
          <p>No Materials</p>
        )}
      </div>
    </div>
  );
};

export default ReserveBlock;
