import React from "react";
import "./ReservationDetail.css";

const ReservationDetail = ({ reservation, onClose, editable, onDelete }) => {
  // Convert Date Dictionary to String
  const formatDate = (datePicked) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[datePicked.month - 1]} ${datePicked.day}, ${
      datePicked.year
    }`;
  };

  // Convert Cost Int to String
  const formatCost = (cost) => {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  };

  // Convert Time to String
  const formatTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amPm}`;
  };

  // Convert Materials Array to String
  const formatMaterials = (materials) => {
    if (!materials || materials.length === 0) return "None";
    return materials
      .map((material) => `${material.count} ${material.name}`)
      .join(", ");
  };

  return (
    <div className="reservation-detail-overlay" onClick={onClose}>
      <div className="reservation-detail" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{reservation.eventName}</h2>
        <h3>
          Client:{" "}
          <span className="unbold">
            {reservation.firstName} {reservation.lastName}
          </span>
        </h3>
        <h3>
          Status: <span className="unbold">{reservation.status}</span>
        </h3>
        <h3>
          Location:{" "}
          <span className="unbold">{reservation.selectedLocation}</span>
        </h3>
        <h3>
          Activity Type: <span className="unbold">{reservation.activity}</span>
        </h3>
        <h3>
          Party Size: <span className="unbold">{reservation.partySize}</span>
        </h3>
        <h3>
          Date:{" "}
          <span className="unbold">{formatDate(reservation.datePicked)}</span>
        </h3>
        <h3>
          Time:{" "}
          <span className="unbold">
            {formatTime(reservation.startTime)}-
            {formatTime(reservation.endTime)}
          </span>
        </h3>
        <h3>
          Cost: <span className="unbold">{formatCost(reservation.cost)}</span>
        </h3>
        <h3>
          Materials:{" "}
          <span className="unbold">
            {formatMaterials(reservation.materialsNeeded)}
          </span>
        </h3>
        <h3>
          Comments:{" "}
          <span className="unbold">
            {reservation.comments === "" ? " None" : null}
          </span>
        </h3>
        {reservation.comments !== "" ? (
          <div className="reservation-detail-comments">
            <p>{reservation.comments}</p>
          </div>
        ) : null}

        {editable ? (
          <div className="reservation-detail-button-container">
            {/* <button className="reservation-detail-cancel">
              <h3>Delete</h3>
            </button> */}
            {/* <button className="reservation-detail-edit">
              <h3>Edit</h3>
            </button> */}
          </div>
        ) : null}

        {onDelete ? (
          <div className="reservation-detail-button-container">
            <button className="reservation-detail-cancel" onClick={onDelete}>
              <h3>Delete</h3>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReservationDetail;
