import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./Header.css";
import logo from "../images/logo.png";

const Header = () => {
  const location = useLocation();

  // Function to determine if the link should have an "active" class
  const isActive = (path) => location.pathname === path;

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <nav className="nav">
        <Link to="/about" className={isActive("/about") ? "active" : ""}>
          About Us
        </Link>
        <Link to="/features" className={isActive("/features") ? "active" : ""}>
          Features
        </Link>
        <Link to="/clients" className={isActive("/clients") ? "active" : ""}>
          Clients
        </Link>
        <Link to="/interns" className={isActive("/interns") ? "active" : ""}>
          Interns
        </Link>
      </nav>
      <div className="divider"></div>
      <div className="auth-buttons">
        <Link to="/login">
          <button className="login">Log In</button>
        </Link>
        <Link to="/register">
          <button className="getstarted">Get Started</button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
