import React from "react";
import { Link } from "react-router-dom";

import launchPic from "../images/bookin-launch-pic.png";
import "./Landing-Home.css";

const Landing = () => {
  return (
    <div className="landing-home">
      <div className="horizontal-align">
        <div className="container">
          <img src={launchPic} alt="demo"></img>

          <div>
            <h1>Bookin</h1>
            <h3>Reserving spaces has never been easier.</h3>
            <div className="auth-buttons">
              <Link to="/register">
                <button className="getstarted">Get Started</button>
              </Link>
              <Link to="/login">
                <button className="login">Log In</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
