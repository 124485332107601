import React from "react";
import "./ReservationsItemized.css";

const ReservationsItemized = ({ reservations }) => {
  const formatDate = (datePicked) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[datePicked.month - 1]} ${datePicked.day}, ${
      datePicked.year
    }`;
  };

  // Convert Cost Int to String
  const formatCost = (cost) => {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  };

  // Convert Time to String
  const formatTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amPm}`;
  };

  // Convert Materials Array to String
  const formatMaterials = (materials) => {
    if (!materials || materials.length === 0) return "None";
    return materials
      .map((material) => `${material.count} ${material.name}`)
      .join(", ");
  };

  return (
    <div className="reservations-itemized">
      <table>
        {reservations
          ? reservations.map((reservation, index) => (
              <div className="table-section" key={index}>
                <tbody>
                  <th>Reservation #{index + 1}</th>
                  <tr>
                    <th>Event</th>
                    <td>{reservation.eventName}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{reservation.selectedLocation}</td>
                  </tr>
                  <tr>
                    <th>Party Size</th>
                    <td>{reservation.partySize}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{formatDate(reservation.datePicked)}</td>
                  </tr>
                  <tr>
                    <th>Time</th>
                    <td>
                      {formatTime(reservation.startTime)} -{" "}
                      {formatTime(reservation.endTime)}
                    </td>
                  </tr>
                  <tr>
                    <th>Cost</th>
                    <td>{formatCost(reservation.cost)}</td>
                  </tr>
                  {reservation.materialsNeeded.length > 0 && (
                    <tr>
                      <th>Materials</th>
                      <td>{formatMaterials(reservation.materialsNeeded)}</td>
                    </tr>
                  )}
                  {reservation.comments && (
                    <tr>
                      <th>Comments</th>
                      <td>{reservation.comments}</td>
                    </tr>
                  )}
                </tbody>
              </div>
            ))
          : null}
      </table>
    </div>
  );
};

export default ReservationsItemized;
