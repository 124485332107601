import React from "react";
import "./Clients.css";

const Clients = () => {
  return (
    <>
      <div className="horizontal-align">
        <div className="container">
          <h1>Coming Soon</h1>
        </div>
      </div>
    </>
  );
};

export default Clients;
