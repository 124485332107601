import React from "react";
import "./LoadingPopup.css";

const LoadingPopup = ({ isLoading, message = "Loading..." }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-popup">
        <div className="spinner"></div> <p>{message}</p>
      </div>
    </div>
  );
};

export default LoadingPopup;
