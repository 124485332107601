import { React, useState } from "react";
import "./MaterialsSelect.css";

const MaterialsSelect = ({
  formData,
  materialData,
  onPlus,
  onRemove,
  onClose,
}) => {
  // Convert Cost Int to String
  function formatCost(cost) {
    if (cost === 0) {
      return "Free";
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cost);
    }
  }

  // State for filter input
  const [filter, setFilter] = useState("");

  // Handler to update the filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter the materials based on the input
  function filterMaterials() {
    // Recursive function to flatten the nested dictionary
    function flattenMaterials(materials, type = null) {
      let result = [];
      for (const [key, value] of Object.entries(materials)) {
        if (typeof value === "object" && !Array.isArray(value)) {
          result = [...result, ...flattenMaterials(value, key)];
        } else {
          result.push({
            name: key,
            cost: value,
            type,
          });
        }
      }

      return result;
    }

    const mappedMaterials = flattenMaterials(materialData);
    const filteredMaterials = mappedMaterials.filter((material) =>
      material.name.toLowerCase().includes(filter.toLowerCase())
    );
    if (filteredMaterials.length > 0) {
      return filteredMaterials.map((material) => (
        <MaterialCard key={material.name} material={material} depth={0} />
      ));
    } else {
      return <p className="material-empty">No Materials</p>;
    }
  }

  const [expandedCategories, setExpandedCategories] = useState({});

  // Toggles Expanded Categories
  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Categorize Materials
  function categorizeMaterials(dict, depth) {
    return Object.entries(dict).map(([key, value]) => {
      if (typeof value === "object") {
        return (
          <MaterialCategory
            key={key}
            category={key}
            children={value}
            depth={depth}
            expanded={expandedCategories[key]}
            onToggle={toggleCategory}
          />
        );
      } else {
        return (
          <MaterialCard
            key={key}
            material={{ name: key, cost: value }}
            depth={depth}
          />
        );
      }
    });
  }

  const MaterialCard = ({ material, depth }) => {
    const alreadySelected =
      formData.materialsNeeded.findIndex((m) => m.name === material.name) !==
      -1;

    return (
      <button
        className={`material-card ${alreadySelected ? "selected" : null}`}
        style={{ "--depth": depth }}
        onClick={() =>
          alreadySelected ? onRemove(material) : onPlus(material)
        }
      >
        <h3>
          {material.name}: {formatCost(material.cost)}
          <i className="fas fa-plus"></i>
        </h3>
      </button>
    );
  };

  const MaterialCategory = ({
    category,
    children,
    depth,
    expanded,
    onToggle,
  }) => {
    return (
      <>
        <button
          className={"material-category"}
          style={{ "--depth": depth }}
          onClick={() => onToggle(category)}
        >
          <h3>
            {category}
            <i
              className={
                expanded ? "fas fa-chevron-down" : "fas fa-chevron-right"
              }
            ></i>
          </h3>
        </button>

        {expanded ? categorizeMaterials(children, depth + 1) : null}
      </>
    );
  };

  return (
    <div className="materials-select-overlay" onClick={onClose}>
      <div className="materials-select" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h3>Select Materials</h3>
        <div className="materials-search">
          <input
            type="text"
            name="eventName"
            id="eventName"
            value={filter}
            placeholder="Search for an item"
            onChange={handleFilterChange}
          />
          <i className="fas fa-search"></i>
        </div>
        <div className="materials-list">
          {filter === ""
            ? categorizeMaterials(materialData, 0)
            : filterMaterials()}
        </div>
      </div>
    </div>
  );
};

export default MaterialsSelect;
